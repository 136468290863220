import { DropdownOption } from "../../common/models/DropdownOption";

export interface WorkOrderModel {
  parent: string;
  targstartdate: string;
  status_description: string;
  description_longdescription: string;

  //address
  dte_addcombo: string;

  //event id not always present
  dte_externalid: string;

  //INS (Inservice), OMS, empty
  dte_externalsys: string;
  description: string;
  dte_tasktype: string;
  lead: string;
  wonum: string;

  //crew id
  dte_assignment: string;
  siteid: string;
  targcompdate: string;

  dte_workorder: {
    //documentum link
    dte_dctmurl: string;
    parent_wonum: string;
    parent_desc: string;
  }[];

  worklog: WorkLogItemModel[];

  status: WorkOrderStatus;
}

export interface WorkLogItemModel {
  modifyby: string;
  logtype: string;
  description_longdescription: string;
  modifydate: string;
  description: string;
  logtype_description: string;
}

export type WorkOrderStatus =
  | "APPR"
  | "FDDISPATCHED"
  | "FDENROUTE"
  | "FDARRIVE"
  | "FDONHOLD"
  | "FDRESUME"
  | "FDCOMP";

const approvedStatus = { label: "APPR", value: "APPR" };
const dispatchedStatus = { label: "FDDISPATCHED", value: "FDDISPATCHED" };
const enrouteStatus = { label: "FDENROUTE", value: "FDENROUTE" };
const arriveStatus = { label: "FDARRIVE", value: "FDARRIVE" };
const onholdStatus = { label: "FDONHOLD", value: "FDONHOLD" };
const resumeStatus = { label: "FDRESUME", value: "FDRESUME" };
const completedStatus = { label: "FDCOMP", value: "FDCOMP" };

const workOrderEligibleStatuses = {
  APPR: [
    approvedStatus,
    dispatchedStatus,
    enrouteStatus,
    arriveStatus,
    onholdStatus,
    resumeStatus,
    completedStatus,
  ],
  FDDISPATCHED: [
    dispatchedStatus,
    enrouteStatus,
    arriveStatus,
    onholdStatus,
    resumeStatus,
    completedStatus,
  ],
  FDENROUTE: [
    dispatchedStatus,
    enrouteStatus,
    arriveStatus,
    onholdStatus,
    resumeStatus,
    completedStatus,
  ],
  FDARRIVE: [
    dispatchedStatus,
    enrouteStatus,
    arriveStatus,
    onholdStatus,
    resumeStatus,
    completedStatus,
  ],
  FDONHOLD: [
    dispatchedStatus,
    enrouteStatus,
    arriveStatus,
    onholdStatus,
    resumeStatus,
    completedStatus,
  ],
  FDRESUME: [
    dispatchedStatus,
    enrouteStatus,
    arriveStatus,
    onholdStatus,
    resumeStatus,
    completedStatus,
  ],
  FDCOMP: [completedStatus],
};

export const getWorkOrderStatuses = (
  status: WorkOrderStatus
): DropdownOption[] => {
  return workOrderEligibleStatuses[status];
};
