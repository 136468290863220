import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getTreeTrimActivitiesForJob,
  getTreeTrimOutageInfoForJob,
} from "../lib/api/treeTrimApi";
import { TreeTrimActivity } from "../lib/models/TreeTrimActivity";
import { TreeTrimConfirmQuestionsModel } from "../lib/models/TreeTrimConfirmQuestionsModel";
import { TreeTrimOutageInfo } from "../lib/models/TreeTrimOutageInfo";
import { AppDispatch } from "../ReduxStore";

export interface TreeTrimState {
  activities: TreeTrimActivity[];
  outageInfo: TreeTrimOutageInfo[];
  questions: TreeTrimConfirmQuestionsModel;
}

const initialState: TreeTrimState = {
  activities: [],
  outageInfo: [],
  questions: { treeRelatedOutage: null, treeTrimNeeded: null },
};

export const getTreeTrimActivities = createAsyncThunk<
  TreeTrimActivity[],
  string,
  { dispatch: AppDispatch; reject: string }
>(
  "treeTrim/getTreeTrimActivities",
  async (jobId, { dispatch, rejectWithValue }) => {
    const res = await getTreeTrimActivitiesForJob(jobId);

    if (res.tag === "ok" && (res.data as unknown) !== "") {
      return res.data;
    } else {
      console.log(res);
    }

    return rejectWithValue(jobId);
  }
);

export const getTreeTrimOutageInfo = createAsyncThunk<
  TreeTrimOutageInfo[],
  string,
  { dispatch: AppDispatch; reject: string }
>(
  "treeTrim/getTreeTrimOutageInfo",
  async (jobId, { dispatch, rejectWithValue }) => {
    const res = await getTreeTrimOutageInfoForJob(jobId);

    if (res.tag === "ok" && (res.data as unknown) !== "") {
      return res.data;
    } else {
      console.log(res);
    }

    return rejectWithValue(jobId);
  }
);

export const treeTrimSlice = createSlice({
  name: "treeTrim",
  initialState,
  reducers: {
    setTreeTrimActivities: (
      state,
      action: PayloadAction<TreeTrimActivity[]>
    ) => {
      state.activities = action.payload;
    },
    setTreeTrimOutageInfo: (
      state,
      action: PayloadAction<TreeTrimOutageInfo[]>
    ) => {
      state.outageInfo = action.payload;
    },
    setTreeTrimConfirmQuestions: (
      state,
      action: PayloadAction<TreeTrimConfirmQuestionsModel>
    ) => {
      state.questions = action.payload;
    },
    clearTreeTrimConfirmQuestions: (state) => {
      state.questions = { treeRelatedOutage: null, treeTrimNeeded: null };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTreeTrimActivities.fulfilled, (state, action) => {
      state.activities = action.payload.sort((a, b) =>
        new Date(a.createDate) > new Date(b.createDate) ? 1 : -1
      );
    });
    builder.addCase(getTreeTrimActivities.rejected, (state, action) => {
      alert(`Unable to retrieve activities for ${action.payload}`);
    });
    builder.addCase(getTreeTrimOutageInfo.fulfilled, (state, action) => {
      state.outageInfo = action.payload.sort((a, b) =>
        new Date(a.createDate) > new Date(b.createDate) ? 1 : -1
      );
    });
    builder.addCase(getTreeTrimOutageInfo.rejected, (state, action) => {
      alert(`Unable to retrieve outage info for ${action.payload}`);
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setTreeTrimActivities,
  setTreeTrimOutageInfo,
  setTreeTrimConfirmQuestions,
  clearTreeTrimConfirmQuestions,
} = treeTrimSlice.actions;

export default treeTrimSlice.reducer;
