import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface onlineState {
  value: any;
}

const initialState: onlineState = {
  value: window.navigator.onLine,
};

export const onlineSlice = createSlice({
  name: "online",
  initialState,
  reducers: {
    setOnline: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOnline } = onlineSlice.actions;

export default onlineSlice.reducer;
