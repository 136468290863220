import React from "react";
import { setTreeTrimConfirmQuestions } from "../../features/treeTrimSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { getYesNoRadioOptionValue } from "../../lib/Utility";
import { RadioControl } from "../controls/RadioControl";
import WarningBox from "../WarningBox";

const TreeTrimConfirmQuestions: React.FC<{
  errors: string[];
  errorMessages: { activityErrorMessage: string; outageErrorMessage: string };
}> = ({ errors, errorMessages }) => {
  const dispatch = useAppDispatch();
  const treeTrimConfirmQuestions = useAppSelector(
    (state) => state.treeTrim.questions
  );
  const hasError = (field: string) => {
    return errors.indexOf(field) !== -1;
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col w-3/4">
        <div className="my-1">
          <RadioControl
            label="Is Tree Trim Needed?"
            name="treeTrimNeeded"
            error={hasError("treeTrimNeeded")}
            value={getYesNoRadioOptionValue(
              treeTrimConfirmQuestions.treeTrimNeeded
            )}
            options={["Yes", "No"]}
            required={true}
            disabled={false}
            setValue={(value) => {
              const boolValue = value === "Yes" ? true : false;
              const questions = {
                ...treeTrimConfirmQuestions,
                treeTrimNeeded: boolValue,
              };
              dispatch(setTreeTrimConfirmQuestions(questions));
            }}
          />
        </div>
        <div className="">
          {hasError("treeTrimNeeded") ? (
            <WarningBox message={errorMessages.activityErrorMessage} />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex flex-col w-3/4">
        <div className="my-1">
          <RadioControl
            label="Is There a Tree Related Outage?"
            name="treeRelatedOutage"
            error={hasError("treeRelatedOutage")}
            value={getYesNoRadioOptionValue(
              treeTrimConfirmQuestions.treeRelatedOutage
            )}
            options={["Yes", "No"]}
            required={true}
            disabled={false}
            setValue={(value) => {
              const boolValue = value === "Yes" ? true : false;
              const questions = {
                ...treeTrimConfirmQuestions,
                treeRelatedOutage: boolValue,
              };
              dispatch(setTreeTrimConfirmQuestions(questions));
            }}
          />
        </div>
        <div className="">
          {hasError("treeRelatedOutage") ? (
            <WarningBox message={errorMessages.outageErrorMessage} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default TreeTrimConfirmQuestions;
