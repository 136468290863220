import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "../lib/Utility";

export interface roleState {
  value: Role | null;
}

const initialState: roleState = {
  value: null,
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<Role>) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRole } = roleSlice.actions;

export default roleSlice.reducer;
