import React from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import * as Api from "../../lib/Api";
import * as Util from "../../lib/Utility";

const HistoricJobModal = (props: {
  jobs: Api.HistoricJob[];
  index: number;
}) => {
  const [selectedJobIndex, setSelectedJobIndex] = React.useState<number>(
    props.index
  );

  const changeSelectedJob = (dir: "left" | "right") => {
    if (dir === "right" && props.jobs[selectedJobIndex + 1]) {
      setSelectedJobIndex(selectedJobIndex + 1);
    } else if (dir === "left" && props.jobs[selectedJobIndex - 1]) {
      setSelectedJobIndex(selectedJobIndex - 1);
    }
  };

  const selectedJob = props.jobs[selectedJobIndex];
  const job = props.jobs[props.index];

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default", width: "75%" }}
        className="responsive-modal"
        trigger={
          <tr className="hover:bg-gray-200 cursor-pointer">
            <HistoricJobTableRow job={job} />
          </tr>
        }
      >
        {(close: React.MouseEventHandler<HTMLButtonElement>) => (
          <div className="text-black mx-1.25 my-1.25 sm:mx-2.5 sm:my-2.5">
            <div className="w-full inline-flex flex-no-wrap sm:justify-center mb-1.5 relative">
              <div className="inline-flex flex-no-wrap justify-between items-center">
                <button
                  onClick={() => changeSelectedJob("left")}
                  disabled={!props.jobs[selectedJobIndex - 1]}
                >
                  <FontAwesomeIcon icon={faChevronLeft} className={``} />
                </button>

                <h1 className="text-2xl mx-1.5">{selectedJob.jobDisplayId}</h1>
                <button
                  onClick={() => changeSelectedJob("right")}
                  disabled={!props.jobs[selectedJobIndex + 1]}
                >
                  <FontAwesomeIcon icon={faChevronRight} className={``} />
                </button>
              </div>
              <button
                onClick={close}
                className="bg-dte-500 absolute right-0 text-white py-0.25 px-0.5"
              >
                Close
              </button>
            </div>
            <div className="border-l border-r border-t border-gray-300 mb-0.5">
              <Row label="Premise Id" value={selectedJob.premiseId} />
              <Row label="Job Status" value={selectedJob.jobStatus} />
              <Row label="Job Type" value={selectedJob.jobType} />
              <Row label="Job Sub Type" value={selectedJob.jobSubType} />
              <Row label="Circuit" value={selectedJob.circuit} />
              <Row label="PRNs" value={selectedJob.prnFlag} />
              <Row
                label="Outage Date"
                value={new Date(selectedJob.outageDate).toLocaleString(
                  ...Util.dateFormat
                )}
              />
              <Row
                label="Restoration Date"
                value={new Date(selectedJob.RestorationDate).toLocaleString(
                  ...Util.dateFormat
                )}
              />
              <Row label="Cause Code" value={selectedJob.causeCode} />
            </div>
            <div className="mt-2.5 text-left">
              <h2 className="text-xl">Comments</h2>
              <div className="mt-1.5">
                <Comments comments={selectedJob.comments} />
              </div>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

const Row = (props: {
  label: string;
  value?: string | number | React.ReactNode;
  children?: any;
}) => {
  return (
    <div
      className="py-0.5 px-1 min-h-3.5 flex justify-between border-b border-gray-300 items-center"
      key={props.label}
    >
      <div className="mr-2">{props.label}:</div>
      <div style={{ wordBreak: "break-word" }} className="text-right">
        {props.children
          ? props.children
          : props.value && props.value !== ""
          ? props.value
          : "N/A"}
      </div>
    </div>
  );
};

const HistoricJobTableRow: React.FC<{
  job: Api.HistoricJob;
}> = ({ job }) => {
  return (
    <>
      <td className="border text-center py-0.5 px-0.25 md:px-1 font-bold">
        {job.jobDisplayId}
      </td>
      <td className="border px-0.25 md:px-1 text-center py-0.5">
        {job.jobType}
      </td>
      <td className="border px-0.25 md:px-1 text-center py-0.5">
        {job.causeCode}
      </td>
      <td className="border px-0.25 md:px-1 text-center py-0.5">
        {job.circuit}
      </td>
      <td className="border px-0.25 md:px-1 text-center py-0.5">
        {new Date(job.outageDate).toLocaleString(...Util.dateFormat)}
      </td>
      <td className="border px-0.25 md:px-1 text-center py-0.5">
        {new Date(job.RestorationDate).toLocaleString(...Util.dateFormat)}
      </td>
    </>
  );
};

export default HistoricJobModal;

const Comments: React.FC<{ comments: Api.Comments }> = ({ comments }) => {
  if (Array.isArray(comments)) {
    return (
      <>
        {comments.sort(Util.sortCommentsByDate).map((x, i) => (
          <SingleComment comment={x} key={i} />
        ))}
      </>
    );
  } else {
    return <></>;
  }
};

const SingleComment: React.FC<{ comment: Api.SingleComment }> = ({
  comment,
}) => {
  if (comment.comment === "") {
    return null;
  } else {
    return (
      <div className="inline-flex flex-no-wrap">
        <p className="mr-0.5 whitespace-no-wrap">
          {comment.timestamp &&
            new Date(comment.timestamp).toLocaleString(...Util.dateFormat)}
        </p>
        <p className="mr-0.5">:</p>
        {comment.comment.startsWith("https") ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={comment.comment}
            className="underline text-dte-600"
          >
            PHOTO: {comment.comment}
          </a>
        ) : (
          <p>{comment.comment}</p>
        )}
      </div>
    );
  }
};
