import clsx from "clsx";
import React from "react";

const Label: React.FC<{
  label?: string;
  labelHTML?: boolean;
  error?: boolean;
  required?: boolean;
}> = ({ label, labelHTML, error, required }) => {
  return (
    <div className="flex flex-no-wrap mb-0.5">
      {labelHTML ? (
        <label
          className={clsx("font-medium mr-0.25", error && "error-control")}
          dangerouslySetInnerHTML={{ __html: label || "" }}
        ></label>
      ) : (
        <label
          className={clsx("font-medium mr-0.25", error && "error-control")}
        >
          {label}
        </label>
      )}

      {required && <p className="text-red-600">*</p>}
    </div>
  );
};

export const NumericControl: React.FC<{
  label?: string;
  placeholder?: string;
  value?: number;
  labelHTML?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  containerClass?: string;
  controlClass?: string;
  setValue: (value: number) => void;
}> = ({
  label,
  placeholder,
  value,
  containerClass,
  controlClass,
  setValue,
  required,
  disabled,
  error,
  labelHTML,
}) => {
  const formatNumber = (e: React.FormEvent<HTMLInputElement>): number => {
    e.currentTarget.value = e.currentTarget.value.replace(/^0+/, "");
    return Number(e.currentTarget.value);
  };

  const setNumber = (value: number) => {
    if (value < 0) {
      value = 0;
    }
    setValue(value);
  };

  return (
    <div className={clsx("flex flex-col", containerClass)}>
      {label ? (
        <Label
          label={label}
          labelHTML={labelHTML}
          required={required}
          error={error}
        />
      ) : (
        <></>
      )}
      <div className={clsx("flex-inline h-2.5", controlClass)}>
        <button
          className="w-1/4 h-2.5 bg-dte-500 text-white"
          disabled={disabled}
          onClick={() => {
            setNumber((value || 0) - 1);
          }}
        >
          -
        </button>
        <input
          type="number"
          className={clsx(
            "w-1/2 h-2.5 border border-dte-500 text-center grants-specical-number-input",
            error && "error-control"
          )}
          min="0"
          value={value}
          disabled={disabled}
          onChange={(e) => {
            setNumber(formatNumber(e));
          }}
          placeholder={placeholder}
        />
        <button
          className="w-1/4 h-2.5 bg-dte-500 text-white"
          disabled={disabled}
          onClick={() => {
            setNumber((value || 0) + 1);
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};
