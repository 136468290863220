export interface TreeTrimConfirmQuestionsModel {
  treeTrimNeeded: boolean | null;
  treeRelatedOutage: boolean | null;
}

export const validateTreeTrimConfirmQuestions = (
  questions: TreeTrimConfirmQuestionsModel,
  hasActivities: boolean,
  hasOutageInfo: boolean
): string[] => {
  const questionErrors: string[] = [];

  if (
    questions.treeTrimNeeded === null ||
    (questions.treeTrimNeeded === true && !hasActivities)
  ) {
    questionErrors.push("treeTrimNeeded");
  }
  if (
    questions.treeRelatedOutage === null ||
    (questions.treeRelatedOutage === true && !hasOutageInfo)
  ) {
    questionErrors.push("treeRelatedOutage");
  }
  return questionErrors;
};
