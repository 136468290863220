import {
  faCaretDown,
  faCaretUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Api from "../lib/Api";
import { getJobsByCrews } from "../features/jobSlice";
import { useAppSelector, useAppDispatch } from "../lib/hooks";
import CrewBulkAck from "../components/modals/CrewBulkAck";
import CrewStatusModal from "../components/modals/CrewStatusModal";

type SortKey = keyof Api.JobDetailShort;

type SortDir = "asc" | "desc";

const CrewsPage = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const crewJobs = useAppSelector((state) => state.jobs.crewJobs);
  const [crewStatuses, setCrewStatuses] = React.useState<
    Api.CrewStatusByCrewList[]
  >([]);
  const [sortKey, setSortKey] = React.useState<SortKey | null>(null);
  const [sortDir, setSortDir] = React.useState<SortDir>("desc");

  const [crewFilter, setCrewFilter] = React.useState("ALL");

  const [crewStatusLoading, setCrewStatusLoading] = React.useState(false);
  const [selectedJobs, setSelectedJobs] = React.useState<string[]>([]);

  const [filter, setFilter] = React.useState<string>("");

  React.useEffect(() => {
    dispatch(getJobsByCrews());
    setCrewStatusLoading(true);
    Api.getCrewStatusByCrewList(role?.crews ?? [])
      .then((res) => {
        if (res.tag === "ok") {
          setCrewStatuses(res.data);
        } else {
          alert("Error fetching crew statuses");
        }
      })
      .finally(() => setCrewStatusLoading(false));
  }, []);

  const setSort = (key: SortKey) => {
    if (key === sortKey) {
      if (sortDir === "desc") {
        setSortDir("asc");
      } else if (sortDir === "asc") {
        setSortDir("desc");
        setSortKey(null);
      }
    } else {
      setSortKey(key);
    }
  };

  const toggleCrewFilter = (crew: string) => {
    if (crew === crewFilter) {
      setCrewFilter("ALL");
    } else {
      setCrewFilter(crew);
    }
  };

  const loggedInCrews = crewStatuses
    .filter((x) => "LogonStatus" in x && x.LogonStatus === "Y")
    .map((x) => x.crewId);

  const handleChecked = (checked: boolean, jobId: string) => {
    if (checked) {
      setSelectedJobs([jobId, ...selectedJobs]);
    } else {
      const otherJobs = selectedJobs.filter((x) => x !== jobId);
      setSelectedJobs(otherJobs);
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedJobs(crewJobs.map((c) => c.jobId));
    } else {
      setSelectedJobs([]);
    }
  };

  const getSelectedCrews = () => {
    if (!role?.crews) {
      return [];
    }
    return crewJobs
      .filter((c) => selectedJobs.includes(c.jobId))
      .map((c) => c.crewId.split(","))
      .flat()
      .filter((r) => role.crews.includes(r))
      .filter((val, idx, self) => self.indexOf(val) === idx)
      .sort((a, b) => a.localeCompare(b));
  };

  const handleFilterChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setFilter(e.currentTarget.value);
  };

  return (
    <div className="flex flex-wrap text-black mx-1">
      <div className="w-full h-full md:w-3/10 md:mt-0.5 flex flex-wrap mb-1 justify-center md:justify-start">
        {role?.crews.map((crew, i) => {
          const jobsAssigned = crewJobs.filter(
            (x) => x.crewId && x.crewId.split(",").includes(crew)
          ).length;
          const crewStatus = crewStatuses.find(
            (x) => x.crewId && x.crewId.split(",").includes(crew)
          );
          if (crewStatusLoading) {
            return (
              <CrewCard
                key={i}
                crewId={crew}
                crewStatus={"Fetching..."}
                jobsAssigned={jobsAssigned}
                logonStatus={"Loading"}
                onClick={() => toggleCrewFilter(crew)}
                selected={crew === crewFilter}
              />
            );
          }
          if (crewStatus?.errorCode) {
            return (
              <CrewCard
                key={i}
                crewId={crew}
                crewStatus={"Error"}
                jobsAssigned={jobsAssigned}
                logonStatus={"Err"}
                onClick={() => toggleCrewFilter(crew)}
                selected={crew === crewFilter}
              />
            );
          } else if (crewStatus) {
            return (
              <CrewCard
                key={i}
                crewId={crew}
                crewStatus={crewStatus.crewStatus}
                jobsAssigned={jobsAssigned}
                logonStatus={crewStatus.LogonStatus}
                onClick={() => toggleCrewFilter(crew)}
                selected={crew === crewFilter}
              />
            );
          }
        })}
      </div>
      <div className="w-full md:w-7/10 md:mt-1 flex flex-wrap max-h-screen h-full overflow-x-auto">
        {role?.tag === "employee" && (
          <CrewBulkAck
            jobs={crewJobs
              .filter((c) => selectedJobs.includes(c.jobId))
              .sort((a, b) => a.jobDisplayId.localeCompare(b.jobDisplayId))}
            crews={getSelectedCrews()}
          />
        )}
        <div className="flex items-center mb-0.25 ml-auto">
          <input
            className={"w-full md:w-20 border-2 border-dte-500 h-2.5 pl-1"}
            placeholder="Search"
            value={filter}
            onChange={handleFilterChange}
          />
          <span
            className="flex items-center justify-center"
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#3464A7",
            }}
          >
            <FontAwesomeIcon
              style={{ color: "white" }}
              className={"float-right"}
              icon={faSearch}
            />
          </span>
        </div>
        <table className="table-auto bg-white w-full text-black border mb-2">
          <thead className="bg-gray-200">
            <tr className="cursor-pointer">
              {role?.tag === "employee" && (
                <th
                  scope="col"
                  className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap cursor-default"
                >
                  <input
                    type="checkbox"
                    id="selectAll"
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    checked={selectedJobs.length === crewJobs.length}
                  />
                </th>
              )}

              <TableHeader
                value="crewId"
                label="Crew"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
              <TableHeader
                value="crewAssignmentStatus"
                label="Crew Assign."
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
              <TableHeader
                value="customerOut"
                label="Cust. Out"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
              <TableHeader
                value="jobId"
                label="Job"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
              <TableHeader
                value="jobType"
                label="Type"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
              <TableHeader
                value="jobSubType"
                label="Job Sub Type"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
              <TableHeader
                value="circuit"
                label="Circuit"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
              <TableHeader
                value="callCode"
                label="Call Code"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
            </tr>
          </thead>
          <tbody>
            {role
              ? sortByHeader(sortKey, sortDir, crewJobs)
                  .filter((x) =>
                    crewFilter === "ALL"
                      ? x
                      : x.crewId.split(",").includes(crewFilter)
                  )
                  .filter((x) =>
                    filter.length > 0
                      ? (x.crewId ?? "")
                          .toUpperCase()
                          .includes(filter.toUpperCase()) ||
                        (x.customerOut.toString() ?? "").includes(filter) ||
                        x.jobDisplayId
                          .toUpperCase()
                          .includes(filter.toUpperCase()) ||
                        (x.jobType ?? "")
                          .toLocaleUpperCase()
                          .includes(filter.toUpperCase()) ||
                        (x.jobSubType ?? "")
                          .toUpperCase()
                          .includes(filter.toUpperCase()) ||
                        (x.circuit ?? "")
                          .toUpperCase()
                          .includes(filter.toUpperCase()) ||
                        (x.callCode ?? "")
                          .toUpperCase()
                          .includes(filter.toUpperCase())
                      : x
                  )
                  .map((job, i) => (
                    <TableRow
                      job={job}
                      key={i}
                      _key={job.jobDisplayId}
                      crews={loggedInCrews}
                      checked={selectedJobs.includes(job.jobId)}
                      handleChecked={handleChecked}
                    />
                  ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

type LogonStatus = "Y" | "N" | "Err" | "Loading";
type LogonStatusProps = {
  status: LogonStatus;
};

const LogonStatusUI = (props: LogonStatusProps) => {
  if (props.status === "Err") {
    return (
      <div className="inline-flex flex-no-wrap items-center">
        <div className="w-0.5 h-0.5 rounded-ful bg-red-400 mr-0.5" />
        <p className="font-bold">Error fetching status</p>
      </div>
    );
  } else if (props.status === "N") {
    return (
      <div className="inline-flex flex-no-wrap items-center">
        <div className="w-0.5 h-0.5 rounded-full bg-gray-500 mr-0.5" />
        <p className="font-bold">Logged Out</p>
      </div>
    );
  } else if (props.status === "Loading") {
    return (
      <div className="inline-flex flex-no-wrap items-center">
        <div className="w-0.5 h-0.5 rounded-full bg-yellow-500 mr-0.5" />
        <p className="font-bold">Loading...</p>
      </div>
    );
  } else {
    return (
      <div className="inline-flex flex-no-wrap items-center">
        <div className="w-0.5 h-0.5 rounded-full bg-green-600 mr-0.5" />
        <p className="font-bold">Logged In</p>
      </div>
    );
  }
};

type CrewCardProps = {
  crewId: string;
  crewStatus: string;
  jobsAssigned: number;
  logonStatus: LogonStatus;
  onClick: Function;
  selected: boolean;
};

const CrewCard = (props: CrewCardProps) => (
  <div
    className={`w-16 h-8 m-0.5 rounded p-1 shadow-md cursor-pointer ${
      props.selected ? "bg-gray-200" : "bg-white"
    }`}
    onClick={() => props.onClick()}
  >
    <p>Crew: {props.crewId}</p>
    <p>Crew Status: {props.crewStatus}</p>
    <p>Jobs Assigned: {props.jobsAssigned}</p>
  </div>
);

const TableHeader = (props: {
  value: SortKey;
  label: String;
  onClick: (key: SortKey) => void;
  sortKey: SortKey | null;
  sortDir: SortDir;
}) => (
  <th
    scope="col"
    className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
    onClick={() => props.onClick(props.value)}
  >
    {props.label}

    <FontAwesomeIcon
      className={`ml-0.5 ${
        props.value === props.sortKey ? "opacity-100" : "opacity-0"
      } `}
      icon={props.sortDir === "asc" ? faCaretUp : faCaretDown}
    />
  </th>
);

const TableRow: React.FC<{
  job: Api.JobDetailShort;
  crews: string[];
  _key: number | string;
  checked: boolean;
  handleChecked: (checked: boolean, jobId: string) => void;
}> = ({ job, crews, _key, checked, handleChecked }) => {
  const history = useHistory();
  const role = useAppSelector((state) => state.role.value);

  return (
    <tr className="hover:bg-gray-200">
      {role?.tag === "employee" && (
        <td className="border text-center py-0.5">
          <input
            checked={checked}
            onChange={(e) => handleChecked(e.target.checked, job.jobId)}
            type="checkbox"
            value={job.jobId}
          />
        </td>
      )}
      <td className="border text-center py-0.5 px-0.5">{job.crewId}</td>
      <td className="border text-center py-0.5">
        <CrewStatusModal jobId={job.jobDisplayId} />
      </td>
      <td className="border px-0.25 md:px-1 text-center">{job.customerOut}</td>
      <td
        className="border px-0.25 md:px-1 text-center font-bold py-0.5 hover:bg-gray-400 cursor-pointer"
        onClick={() => history.push("/details/" + _key)}
      >
        {job.jobDisplayId}
      </td>
      <td className="border px-0.25 md:px-1 text-center py-0.5">
        {job.jobType}
      </td>
      <td className="border px-0.25 md:px-1 text-center py-0.5">
        {job.jobSubType}
      </td>
      <td className="border px-0.25 md:px-1 text-center py-0.5">
        {job.circuit !== "" ? job.circuit : "No Circuit"}
      </td>
      <td className="border px-0.25 md:px-1 text-center py-0.5">
        {job.callCode}
      </td>
    </tr>
  );
};

const sortByHeader = (
  key: SortKey | null,
  dir: SortDir,
  data: Api.JobDetailShort[]
) => {
  if (key !== null) {
    switch (dir) {
      case "asc":
        const ascList = [...data].sort((a, b) => (a[key]! < b[key]! ? 1 : -1));
        return ascList;
      case "desc":
        const descList = [...data].sort((a, b) => (a[key]! > b[key]! ? 1 : -1));
        return descList;
    }
  } else {
    return data;
  }
};

export default CrewsPage;
