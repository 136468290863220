import * as React from "react";

const Row = (props: {
  label: string;
  value?: string | number | React.ReactNode;
  children?: any;
  required?: boolean;
}) => {
  return (
    <div
      className="py-0.5 px-1 min-h-3.5 flex justify-between border-b border-gray-300 items-center"
      key={props.label}
    >
      <div className="mr-2">
        {props.label}
        {props.required && <span className="text-red-600"> * </span>}:
      </div>
      <div style={{ wordBreak: "break-word" }} className="text-right">
        {props.children
          ? props.children
          : props.value && props.value !== ""
          ? props.value
          : "N/A"}
      </div>
    </div>
  );
};

export default Row;
