import { JobDetail } from "../lib/Api";
import Row from "./Row";
import CrewAssignStatusModal from "./modals/CrewAssignStatusModal";
import { useAppSelector } from "../lib/hooks";

const CrewAssignmentsTable = (props: {
  jobDetail: JobDetail;
  afterSubmit: Function;
}) => {
  const role = useAppSelector((state) => state.role.value);
  const crewsOnJob = props.jobDetail.crew.filter(
    (crew) =>
      Object.keys(crew).length !== 0 &&
      crew.crewAssignmentStatus !== "Canceled" &&
      crew.crewAssignmentStatus !== "Completed"
  );

  return (
    <>
      <h2 className="text-xl mb-1 mt-2.5">Crew Assignments</h2>
      <div className="border-l border-r border-t border-gray-300 mb-0.5">
        {crewsOnJob.map(
          (x: {
            crewId: string;
            crewAssignmentStatus: string;
            crewType: string;
          }) =>
            x.crewId === "" && x.crewAssignmentStatus === "" ? null : (
              <Row label={x.crewId} key={x.crewId}>
                <div
                  className={`py-0.5 text-center 
                ${
                  x?.crewAssignmentStatus !== "" ? "text-black" : "text-red-600"
                }
                `}
                >
                  {x?.crewAssignmentStatus}
                  <CrewAssignStatusModal
                    job={props.jobDetail}
                    crewId={x.crewId}
                    crewType={x.crewType}
                    crewsOnJob={crewsOnJob.length}
                    afterSubmit={props.afterSubmit}
                    disabled={
                      ![
                        "Dispatched",
                        "Dispatch Successful",
                        "Acknowledged",
                        "En-Route",
                        "Arrived",
                      ].includes(x?.crewAssignmentStatus) ||
                      (role?.tag !== "employee" &&
                        !role?.crews.includes(x.crewId))
                    }
                  />
                </div>
              </Row>
            )
        )}
      </div>
    </>
  );
};

export default CrewAssignmentsTable;
