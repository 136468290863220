import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CrewAssignmentNotification,
  setNotificationRead,
} from "../../features/crewAssignmentNotificationsSlice";
import { useAppDispatch } from "../../lib/hooks";
import NotificationMessage from "./NotificationMessage";

const CrewAssignmentNotificationMessage = (props: {
  notification: CrewAssignmentNotification;
}) => {
  const dispatch = useAppDispatch();

  const markAsRead = () => {
    if (props.notification.read) {
      return;
    }
    dispatch(setNotificationRead(props.notification));
  };

  return (
    <div className="text-left text-black" onClick={markAsRead}>
      <FontAwesomeIcon icon={faBell} className="text-red-500 mr-1" />
      <>
        <NotificationMessage notification={props.notification} />
      </>
    </div>
  );
};

export default CrewAssignmentNotificationMessage;
