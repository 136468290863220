import Popup from "reactjs-popup";
import { getJobDetailById, JobDetail } from "../../lib/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import CrewAssignmentsTable from "../CrewAssignmentsTable";

const CrewStatusModal = (props: { jobId: string }) => {
  const [loadingData, setLoadingData] = React.useState<boolean>(false);
  const [jobDetail, setJobDetail] = React.useState<JobDetail | null>(null);
  const [modalOpen, setModalOpen] = React.useState(false);

  const getJobDetail = async () => {
    setLoadingData(true);
    const res = await getJobDetailById(props.jobId);
    if (res.tag === "ok") {
      setJobDetail(res.data);
    } else {
      setJobDetail(null);
    }
    setLoadingData(false);
  };

  React.useEffect(() => {
    if (modalOpen) {
      getJobDetail();
    }
  }, [modalOpen]);

  const afterSubmit = () => setTimeout(() => getJobDetail(), 1500);

  return (
    <>
      <Popup
        closeOnDocumentClick={false}
        modal={true}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        onOpen={() => {
          setModalOpen(true);
        }}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className="responsive-modal"
        trigger={
          <div className="inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center w-full cursor-pointer">
            <FontAwesomeIcon className="ml-1" icon={faEye} />
          </div>
        }
      >
        {(closeModal: () => void) => (
          <div className="text-black py-1 flex justify-center flex-col px-1">
            {loadingData ? (
              <div className="text-center text-2xl">
                <FontAwesomeIcon
                  className="mr-0.5"
                  icon={faSpinner}
                  spin={true}
                />
                Loading data...
              </div>
            ) : jobDetail?.crew &&
              jobDetail.crew.filter(
                (crew) =>
                  Object.keys(crew).length !== 0 &&
                  crew.crewAssignmentStatus !== "Canceled" &&
                  crew.crewAssignmentStatus !== "Completed"
              ).length > 0 ? (
              <CrewAssignmentsTable
                jobDetail={jobDetail}
                afterSubmit={afterSubmit}
              />
            ) : (
              <div className="text-center text-2xl">
                There is no active crew on this job.
              </div>
            )}

            <div className="w-full inline-flex flex-no-wrap justify-center items-center my-1">
              <button
                className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default CrewStatusModal;
