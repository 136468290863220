import {
  CrewAssignmentNotification,
  SignalRConfig,
} from "../../features/crewAssignmentNotificationsSlice";
import http from "../Http";

export const getCrewAssignmentNotificationsForUser = async (userId: string) => {
  const res = await http.get<CrewAssignmentNotification[]>(
    `${process.env.REACT_APP_ADMIN_URL}/api/notificationsGet`,
    {
      params: {
        code: process.env.REACT_APP_ADMIN_API_KEY,
        userId,
      },
    }
  );
  return res;
};

export const setCrewAssignmentNotificationAsRead = async (id: string) => {
  const res = await http.put(
    `${process.env.REACT_APP_ADMIN_URL}/api/notificationUpdate`,
    null,
    {
      params: {
        code: process.env.REACT_APP_ADMIN_API_KEY,
        id,
      },
    }
  );
  return res;
};

export const negotiateSignalR = async () => {
  const res = await http.get<SignalRConfig>(
    `${process.env.REACT_APP_ADMIN_URL}/api/negotiate`,
    {
      params: {
        code: process.env.REACT_APP_ADMIN_API_KEY,
      },
    }
  );
  return res;
};
