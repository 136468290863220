import React from "react";
import Popup from "reactjs-popup";
import "react-datepicker/dist/react-datepicker.css";
import {
  JobDetail,
  updateJob,
  JobUpdateReq,
  CrewAssignmentStatusUpdateReq,
  updateCrewAssignmentStatus,
} from "../../lib/Api";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import DamageAssessment, {
  getPoleTypeOptions,
  CrossArmsLocation,
  BrokenPoleLocation,
  getDAForm,
  saveDAForm,
  submitDAForm,
  SiteAccessibility,
  ISLAND,
  POOR_FIELD_CONDITIONS,
  POOR_ROAD_CONDITIONS,
  STANDARD,
  SWAMP,
  SiteAccessibilityDescription,
  DamageLocation,
  EASEMENT_BACKYARD,
  SIDE_OF_ROAD,
  TreeTrim,
  NONE,
  LIGHT,
  HEAVY,
  EXTREME,
  TT_YES,
  TT_NO,
  Voltage,
  SUB_TRANSMISSION,
  DISTRIBUTION,
  PRIMARY,
  SECONDARY,
  SERVICE,
} from "../../lib/api/damageAssessmentApi";
import Row from "../Row";
import clsx from "clsx";
import { TextAreaControl } from "../controls/TextAreaControl";
import { MultiCheckControl } from "../controls/MultiCheckControl";
import { RadioControl } from "../controls/RadioControl";
import { NumericControl } from "../controls/NumericControl";
import { DropdownControl } from "../controls/DropdownControl";
import { PhotoUploadControl } from "../controls/PhotoUploadControl";
import { getYesNoRadioOptionValue } from "../../lib/Utility";
import DAMap from "../DAMap";
import { trackLocationData } from "../../lib/api/trackLocationApi";
import { DropdownOption } from "../../common/models/DropdownOption";
import { useHistory } from "react-router-dom";

type DAForm = {
  okOnArrival: boolean;
  notes: string;
  numberOfBrokenPoles: number;
  arePolesOrdered: boolean | null;
  brokenCrossArms: number;
  spansWireDown: number;
  isOilSpill: boolean | null;
  voltage: Voltage[];
  damagedEquipmentCount: number;
  damageLocation: DamageLocation | null;
  truckAccessible: boolean | null;
  treeTrim: TreeTrim | null;
  siteAccessibility: SiteAccessibility | null;
  siteAccessibilityNotes: string;
  brokenPolesLocations: BrokenPoleLocation[];
  crossArmsLocations: CrossArmsLocation[];
  photos: string[];
  hasPower: boolean | null;
  hasDamage: boolean | null;
};

type DAKey = keyof DAForm;

const defaultDAForm: DAForm = {
  okOnArrival: false,
  notes: "",
  numberOfBrokenPoles: 0,
  arePolesOrdered: null,
  brokenCrossArms: 0,
  spansWireDown: 0,
  isOilSpill: null,
  voltage: [],
  damagedEquipmentCount: 0,
  damageLocation: null,
  truckAccessible: null,
  treeTrim: null,
  siteAccessibility: null,
  siteAccessibilityNotes: "",
  brokenPolesLocations: [],
  crossArmsLocations: [],
  photos: [],
  hasPower: null,
  hasDamage: null,
};

const DamageAssessmentToDAForm = (da: DamageAssessment): DAForm => {
  return {
    ...da,
    arePolesOrdered: da.arePolesOrdered ?? null,
    isOilSpill: da.isOilSpill ?? null,
    damagedEquipmentCount: da.damagedEquipmentCount ?? 0,
    numberOfBrokenPoles: da.numberOfBrokenPoles ?? 0,
    brokenCrossArms: da.brokenCrossArms ?? 0,
    spansWireDown: da.spansWireDown ?? 0,
    voltage: da.voltage ?? [],
    damageLocation: da.damageLocation ?? null,
    truckAccessible: da.truckAccessible ?? null,
    treeTrim: da.treeTrim ?? null,
    siteAccessibility: da.siteAccessibility ?? null,
    siteAccessibilityNotes: da.siteAccessibilityNotes ?? "",
    brokenPolesLocations: da.brokenPolesLocations ?? [],
    crossArmsLocations: da.crossArmsLocations ?? [],
    photos: da.photos ?? [],
    hasPower: da.hasPower ?? null,
    hasDamage: da.hasDamage ?? null,
  };
};

type BrokenPoleSelection = "Pole" | "Cross Arm";
const openFuseText = "There is an open fuse and no other damage. ";

const DamageAssessmentFormModal = (props: {
  job: JobDetail;
  afterSubmit: Function;
}) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const [DAForm, setDAForm] = React.useState<DAForm>(defaultDAForm);
  const [formErrors, setFormErrors] = React.useState<DAKey[]>([]);
  const [brokenItemSelection, setBrokenItemSelection] =
    React.useState<BrokenPoleSelection>("Pole");
  const history = useHistory();

  React.useEffect(() => {
    if (props.job) {
      fetchDAForm(props.job.jobId);
    }
  }, [props.job]);
  const updateDAForm = <T extends DAKey>(key: T, value: DAForm[T]) => {
    setDAForm((prev) => ({ ...prev, [key]: value }));
  };

  const crewsOnJob = props.job.crew.filter(
    (crew) =>
      Object.keys(crew).length !== 0 &&
      crew.crewAssignmentStatus !== "Canceled" &&
      crew.crewAssignmentStatus !== "Completed"
  );

  const closeCrewAssigment =
    role?.crews.length === 1 &&
    crewsOnJob.filter(
      (c) =>
        c.crewId === role.crews[0] &&
        (c.crewType === "DAMAGE ASSESSMENT" ||
          c.crewType === "STORM SUPPORT") &&
        c.crewAssignmentStatus === "Arrived"
    ).length === 1;

  const closeJob =
    role?.tag === "employee" &&
    crewsOnJob.length === 1 &&
    closeCrewAssigment &&
    props.job.extent === "Single Customer";

  const crewJobDoneReq = (
    jobId: string,
    name: string,
    crewId: string
  ): CrewAssignmentStatusUpdateReq => ({
    msgId: jobId,
    jobId: jobId,
    comments: `${crewId} - ${name} updated Crew Assignment Status to Completed`,
    crewAssignmentStatus: "Completed",
    disposition: ["OK ON ARRIVAL"],
  });

  const fetchDAForm = async (jobId: string) => {
    dispatch(setLoading(true));
    const res = await getDAForm(jobId);
    if (res.tag === "ok") {
      if (Object.keys(res.data).length > 0) {
        const form = DamageAssessmentToDAForm(res.data);
        res.data.voltage = res.data.voltage.map((v) =>
          v === DISTRIBUTION ? PRIMARY : v
        );
        if (res.data.treeTrim === NONE) {
          res.data.treeTrim = TT_NO;
        }
        if (
          res.data.treeTrim === EXTREME ||
          res.data.treeTrim === HEAVY ||
          res.data.treeTrim === LIGHT
        ) {
          res.data.treeTrim = TT_YES;
        }
        setDAForm(form);
      } else {
        setDAForm(defaultDAForm);
      }
    } else {
      console.log(res.err);
      setDAForm(defaultDAForm);
    }
    dispatch(setLoading(false));
  };

  const checkForm = () => {
    const errors: DAKey[] = [];

    if (DAForm.okOnArrival) {
      if (DAForm.notes.length === 0) {
        errors.push("notes");
      }
    } else {
      if (DAForm.hasPower === null) {
        errors.push("hasPower");
      }

      if (DAForm.hasDamage === null) {
        errors.push("hasDamage");
      }

      if (DAForm.numberOfBrokenPoles > 0) {
        if (DAForm.arePolesOrdered === null) {
          errors.push("arePolesOrdered");
        }
        if (DAForm.arePolesOrdered !== null && !DAForm.arePolesOrdered) {
          if (
            DAForm.brokenPolesLocations.filter((l) => !l.poleInfo).length > 0
          ) {
            errors.push("brokenPolesLocations");
          }
          if (
            DAForm.brokenPolesLocations.reduce((sum, l) => {
              return sum + (l.quantity ?? 0);
            }, 0) !== DAForm.numberOfBrokenPoles
          ) {
            errors.push("numberOfBrokenPoles");
          }
        }
      } else {
        if (DAForm.brokenPolesLocations?.length > 0) {
          errors.push("numberOfBrokenPoles");
        }
      }

      if (
        DAForm.brokenPolesLocations?.length > 0 &&
        DAForm.brokenPolesLocations.filter((l) => !l.notes).length > 0
      ) {
        errors.push("brokenPolesLocations");
      }

      if (
        DAForm.crossArmsLocations?.length > 0 &&
        DAForm.crossArmsLocations.filter((l) => !l.notes).length > 0
      ) {
        errors.push("crossArmsLocations");
      }

      if (DAForm.isOilSpill === null) {
        errors.push("isOilSpill");
      }

      if (DAForm.voltage.length === 0) {
        errors.push("voltage");
      }

      if (DAForm.damageLocation === null) {
        errors.push("damageLocation");
      }

      if (DAForm.truckAccessible === null) {
        errors.push("truckAccessible");
      }
      if (DAForm.treeTrim === null) {
        errors.push("treeTrim");
      }
      if (DAForm.siteAccessibility === null) {
        errors.push("siteAccessibility");
      }
    }

    setFormErrors(errors);
    return errors;
  };

  const saveForm = async () => {
    dispatch(setLoading(true));
    const user = `${role?.name} - ${role?.id}`;
    const timestamp = new Date().toISOString();

    await trackLocationData("saveDamageAssessment", props.job.jobId, role!);

    const res = await saveDAForm({
      ...DAForm,
      jobId: props.job.jobId,
      circuit: props.job.circuit,
      createUser: user,
      createDate: timestamp,
    });
    if (res.tag === "ok") {
      alert("Damage Assessment Form saved successfully");
      fetchDAForm(props.job.jobId);
    } else {
      console.log(res.err);
      alert("Error saving Damage Assessment Form");
    }
    dispatch(setLoading(false));
  };

  const submitForm = async (close: () => void) => {
    dispatch(setLoading(true));
    const errors = checkForm();

    if (errors.length === 0) {
      const user = `${role?.name} - ${role?.id}`;
      const timestamp = new Date().toISOString();
      const daForm = {
        ...DAForm,
        jobId: props.job.jobId,
        circuit: props.job.circuit,
        submittedOn: timestamp,
        submittedBy: user,
      };

      await trackLocationData("submitDamageAssessment", props.job.jobId, role!);

      const res = await submitDAForm(daForm);
      if (res.tag === "ok") {
        const admsComment: JobUpdateReq = {
          msgId: props.job.jobId,
          jobComments:
            `${role?.name ?? ""} / ${props.job.circuit}\n` +
            `Assessor Notes: ${DAForm.notes}\n` +
            `[${DAForm.hasPower ? "Yes" : "No"}] Power - [${
              DAForm.hasDamage ? "Yes" : "No"
            }] Damage\n` +
            `${
              DAForm.okOnArrival
                ? "OK on Arrival. "
                : `${
                    DAForm.numberOfBrokenPoles > 0
                      ? `[${DAForm.numberOfBrokenPoles}] Broken poles\n`
                      : "[0] No Broken pole\n"
                  }` +
                  `${DAForm.brokenPolesLocations
                    .map(
                      (l) =>
                        " - " +
                        l.poleInfo +
                        " x " +
                        l.quantity +
                        " - " +
                        l.notes
                    )
                    .join("\n")}${
                    DAForm.brokenPolesLocations.length > 0 ? "\n" : ""
                  }` +
                  `${
                    DAForm.brokenCrossArms > 0
                      ? `[${DAForm.brokenCrossArms}] Broken Cross Arms\n`
                      : "[0] No Broken Cross Arms\n"
                  }` +
                  `${DAForm.crossArmsLocations
                    .map((l) => " - " + l.notes)
                    .join("\n")}${
                    DAForm.crossArmsLocations.length > 0 ? "\n" : ""
                  }` +
                  `${
                    DAForm.spansWireDown > 0
                      ? `[${DAForm.spansWireDown}] Span of wires down\n`
                      : "[0] No Span of wires down\n"
                  }` +
                  `[${DAForm.voltage.map((v) => v).join(", ")}] wire(s)\n` +
                  `[${DAForm.damagedEquipmentCount}] Damaged Pole Mounted Equipment (e.g. Transformer, Recloser, Capacitor, Pole Top Switch, Fuse)\n` +
                  `${
                    DAForm.isOilSpill
                      ? "[Yes] Found & Contacted Oil Spill\n"
                      : "[No] Oil Spill\n"
                  }` +
                  `[${DAForm.treeTrim}] Tree Trim is required\n` +
                  `[${
                    DAForm.truckAccessible ? "Yes" : "No"
                  }] Truck Accessible\n` +
                  `${
                    DAForm.siteAccessibility
                  } ${SiteAccessibilityDescription.get(
                    DAForm.siteAccessibility ?? ""
                  )}\n` +
                  `Notes: ${DAForm.siteAccessibilityNotes}\n`
            }`,
        };
        const admsRes = await updateJob(props.job.jobId, admsComment);
        if (admsRes.tag === "ok") {
          if (DAForm.okOnArrival && closeCrewAssigment) {
            const crewReq = crewJobDoneReq(
              props.job.jobId,
              role?.name ?? "",
              role.crews[0]
            );
            const crewRes = await updateCrewAssignmentStatus(
              role.crews[0],
              crewReq
            );
            if (crewRes.tag === "ok") {
              if (DAForm.okOnArrival && closeJob) {
                const closeComment: JobUpdateReq = {
                  msgId: props.job.jobId,
                  jobStatus: {
                    jobStatus: "Completed",
                    comments: `${role.crews[0]} - ${role?.name} closed job ${props.job.jobDisplayId}. ${DAForm.notes}`,
                  },
                  causeCode: {
                    causeCode: "NO WORK DONE",
                    comments: `${role.crews[0]} - ${role?.name} updated Cause Code to NO WORK DONE`,
                  },
                  materialAffected: ["NO MATERIAL AFFECTED"],
                };
                const closeRes = await updateJob(props.job.jobId, closeComment);
                if (closeRes.tag === "ok") {
                  alert("Job closed successfully");
                  history.push("/list");
                } else {
                  alert(
                    "Job Close failed, check your internet connection. You can resend the request through the Drafts page"
                  );
                }
              }
            } else {
              alert(
                "Damage Assessment Form was submitted successfully, but we failed to update crew status - do it manually"
              );
            }
          }
          props.afterSubmit();
          close();
        } else {
          alert(
            "Error updating job chronology with Damage Assessment Form details"
          );
        }
      } else {
        console.log(res.err);
        alert("Error submitting Damage Assessment Form");
      }
    } else {
      alert(
        "Please make sure all required fields have been filled out before submitting"
      );
    }
    dispatch(setLoading(false));
  };

  const switchBrokenItemType = (index: number, type: BrokenPoleSelection) => {
    if (type === "Pole") {
      const item = DAForm.brokenPolesLocations[index];
      if (item) {
        setDAForm((prev) => ({
          ...prev,
          brokenPolesLocations: prev.brokenPolesLocations.filter(
            (x, i) => i !== index
          ),
          // drop all properties except location and notes, before assigning to CrossArmsLocation list
          crossArmsLocations: [
            ...prev.crossArmsLocations,
            Object.fromEntries(
              Object.entries(item).filter(
                (k) => k[0] === "location" || k[0] === "notes"
              )
            ) as CrossArmsLocation,
          ],
        }));
      }
    } else {
      const item = DAForm.crossArmsLocations[index];
      if (item) {
        setDAForm((prev) => ({
          ...prev,
          crossArmsLocations: prev.crossArmsLocations.filter(
            (x, i) => i !== index
          ),
          brokenPolesLocations: [...prev.brokenPolesLocations, item],
        }));
      }
    }
  };

  return (
    <Popup
      closeOnDocumentClick={false}
      modal={true}
      overlayStyle={{ cursor: "default" }}
      contentStyle={{ cursor: "default" }}
      className="responsive-modal responsive-modal-da-form modal-no-padding vf-work-around"
      onOpen={() => fetchDAForm(props.job.jobId)}
      trigger={
        <button className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white">
          Damage Assessment Form
        </button>
      }
    >
      {(close: () => void) => (
        <div className="text-black relative flex flex-col max-h-modal">
          <div
            className="flex flex-wrap justify-center px-2 mb-5 max-h-full overflow-auto"
            id="damage-assesment-form"
          >
            <h1 className="w-full text-2xl p-2.5 text-center">
              Damage Assessment
            </h1>
            <div className="w-full border-l border-r border-t border-gray-300 mb-0.5 px-0.5">
              <Row label="Job Id" value={props.job.jobDisplayId} />
              <Row label="Circuit" value={props.job.circuit} />
            </div>

            <FormRow className="mt-2">
              <RadioControl
                label="Does the customer have full power?"
                name="hasPower"
                error={formErrors.includes("hasPower")}
                value={getYesNoRadioOptionValue(DAForm.hasPower)}
                options={["Yes", "No"]}
                required={true}
                setValue={(value) => {
                  updateDAForm("hasPower", value === "Yes");
                  updateDAForm(
                    "okOnArrival",
                    value === "Yes" &&
                      DAForm.hasDamage !== null &&
                      DAForm.hasDamage !== undefined &&
                      DAForm.hasDamage === false
                  );
                  if (
                    value === "Yes" &&
                    DAForm.hasDamage !== null &&
                    DAForm.hasDamage !== undefined &&
                    DAForm.hasDamage === false
                  ) {
                    setDAForm({
                      ...defaultDAForm,
                      hasPower: true,
                      hasDamage: false,
                      okOnArrival: true,
                    });
                  }
                }}
              />
            </FormRow>
            <FormRow>
              <RadioControl
                label="Are there any repairs required by DTE?"
                name="hasDamage"
                error={formErrors.includes("hasDamage")}
                value={getYesNoRadioOptionValue(DAForm.hasDamage)}
                options={["Yes", "No"]}
                required={true}
                setValue={(value) => {
                  updateDAForm("hasDamage", value === "Yes");
                  updateDAForm(
                    "okOnArrival",
                    !!DAForm.hasPower && value === "No"
                  );
                  if (!!DAForm.hasPower && value === "No") {
                    setDAForm({
                      ...defaultDAForm,
                      hasPower: true,
                      hasDamage: false,
                      okOnArrival: true,
                    });
                  }
                }}
              />
            </FormRow>
            {DAForm.hasPower === true && DAForm.hasDamage === false && (
              <div className="w-full p-1 bg-gray-300 flex flex-col mb-2">
                <div className="flex flex-no-wrap justify-start items-center mb-1.5">
                  <input
                    checked={DAForm.okOnArrival}
                    type="checkbox"
                    className="h-1 w-1"
                    onChange={(e) =>
                      setDAForm({
                        ...defaultDAForm,
                        hasPower: true,
                        hasDamage: false,
                        okOnArrival: e.target.checked,
                      })
                    }
                  />
                  <label className="pl-0.5">Ok on Arrival</label>
                </div>
                <div
                  className={clsx(
                    "w-full flex flex-col",
                    !DAForm.okOnArrival && "disabled"
                  )}
                >
                  <TextAreaControl
                    label="Notes"
                    error={formErrors.includes("notes")}
                    value={DAForm.notes}
                    required={DAForm.okOnArrival}
                    disabled={!DAForm.okOnArrival}
                    setValue={(value) => updateDAForm("notes", value)}
                  />

                  {DAForm.okOnArrival && closeCrewAssigment && (
                    <div className="w-full mt-1 font-medium">
                      Crew assigment will be compleated with disposition code
                      "OK ON ARRIVAL"{" "}
                      {closeJob && (
                        <React.Fragment>
                          and Job will be closed with cause code "NO ISSUES
                          FOUND"
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              className={clsx(
                DAForm.okOnArrival && "disabled",
                "w-full flex flex-col"
              )}
            >
              <FormRow>
                <NumericControl
                  containerClass="xs:mr-3.125"
                  controlClass="w-11.25"
                  label="Number of Broken Poles"
                  error={formErrors.includes("numberOfBrokenPoles")}
                  value={DAForm.numberOfBrokenPoles}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("numberOfBrokenPoles", value)
                  }
                />
                <RadioControl
                  label="Have the Poles been ordered"
                  name="arePolesOrdered"
                  error={formErrors.includes("arePolesOrdered")}
                  value={getYesNoRadioOptionValue(DAForm.arePolesOrdered)}
                  options={["Yes", "No"]}
                  required={DAForm.numberOfBrokenPoles > 0}
                  disabled={
                    DAForm.numberOfBrokenPoles === 0 || DAForm.okOnArrival
                  }
                  setValue={(value) =>
                    updateDAForm("arePolesOrdered", value === "Yes")
                  }
                />
              </FormRow>
              <FormRow>
                <NumericControl
                  containerClass="xs:mr-3.125"
                  controlClass="w-11.25"
                  label="Broken Cross Arms"
                  value={DAForm.brokenCrossArms}
                  error={formErrors.includes("brokenCrossArms")}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) => updateDAForm("brokenCrossArms", value)}
                />

                <NumericControl
                  label="Span of Wires Down"
                  controlClass="w-11.25"
                  error={formErrors.includes("spansWireDown")}
                  value={DAForm.spansWireDown}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) => updateDAForm("spansWireDown", value)}
                />
              </FormRow>
              <FormRow>
                <RadioControl
                  label="Is there an Oil Spill?"
                  name="isOilSpill"
                  error={formErrors.includes("isOilSpill")}
                  value={getYesNoRadioOptionValue(DAForm.isOilSpill)}
                  options={["Yes", "No"]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("isOilSpill", value === "Yes")
                  }
                />
                {DAForm.isOilSpill && (
                  <div className="w-full mt-1 text-red-600 text-xl font-medium">
                    Oil Spill Hotline 313-235-8122
                  </div>
                )}
              </FormRow>
              <FormRow>
                <MultiCheckControl
                  label="Voltage"
                  error={formErrors.includes("voltage")}
                  values={DAForm.voltage}
                  options={[SUB_TRANSMISSION, PRIMARY, SECONDARY, SERVICE]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValues={
                    (values) =>
                      updateDAForm(
                        "voltage",
                        values as Voltage[]
                      ) /* this is bad, but I don't want to figure out what TS trickery is need to make React.FC work with generics*/
                  }
                />
              </FormRow>
              <FormRow>
                <NumericControl
                  label="Pieces of Damaged Pole Mounted Equipment<br/>(Transformer, Recloser, Capacitor, Pole Top Switch, Fuse)"
                  labelHTML={true}
                  error={formErrors.includes("damagedEquipmentCount")}
                  controlClass="w-11.25"
                  value={DAForm.damagedEquipmentCount}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("damagedEquipmentCount", value)
                  }
                />
              </FormRow>
              <FormRow>
                <div className="flex flex-wrap">
                  <div
                    key="openFuse"
                    className="flex flex-no-wrap mr-1.25 items-center"
                  >
                    <input
                      type="checkbox"
                      id="openFuseCheckbox"
                      checked={DAForm.notes.includes(openFuseText)}
                      onChange={(e) =>
                        e.target.checked
                          ? updateDAForm(
                              "notes",
                              openFuseText +
                                DAForm.notes.replaceAll(openFuseText, "")
                            )
                          : updateDAForm(
                              "notes",
                              DAForm.notes.replaceAll(openFuseText, "")
                            )
                      }
                    />
                    <label
                      className="font-medium ml-0.5"
                      htmlFor="openFuseCheckbox"
                    >
                      {openFuseText.slice(0, -2)}
                    </label>
                  </div>
                </div>
                {DAForm.notes.includes(openFuseText) && (
                  <div className="flex flex-wrap w-full">
                    <div className="font-medium mt-1 w-full">For Example:</div>
                    <ul className="list-disc ml-1.25 w-full">
                      <li>Top-Closed (Normal)</li>
                      <li>Bottom-Open (Indicates Trouble)</li>
                    </ul>
                    <div className="m-w-8 max-w-1/2 font-medium">
                      <div className="mr-0.5">
                        <img src="/C_fuse.png" alt="S and C Carriers" />S and C
                        Carriers
                      </div>
                    </div>
                    <div className="m-w-8 max-w-1/2 font-medium">
                      <div className="ml-0.5">
                        <img src="/D_fuse.png" alt="S and C Carriers" />
                        Durable Carriers
                      </div>
                    </div>
                  </div>
                )}
              </FormRow>

              <FormRow>
                <RadioControl
                  label="Location of Damage"
                  name="damageLocation"
                  error={formErrors.includes("damageLocation")}
                  value={DAForm.damageLocation}
                  options={[EASEMENT_BACKYARD, SIDE_OF_ROAD]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("damageLocation", value as DamageLocation)
                  }
                />
              </FormRow>
              <FormRow>
                <RadioControl
                  label="Truck Accessible"
                  name="truckAccessible"
                  error={formErrors.includes("truckAccessible")}
                  value={getYesNoRadioOptionValue(DAForm.truckAccessible)}
                  options={["Yes", "No"]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("truckAccessible", value === "Yes")
                  }
                />
              </FormRow>
              <FormRow>
                <RadioControl
                  label="Tree Trim Required?"
                  name="treeTrim"
                  error={formErrors.includes("treeTrim")}
                  value={DAForm.treeTrim}
                  options={[TT_YES, TT_NO]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("treeTrim", value as TreeTrim)
                  }
                />
              </FormRow>
              <FormRow className="mb-1-i">
                <RadioControl
                  label="Site Accessibility"
                  name="siteAccessibility"
                  error={formErrors.includes("siteAccessibility")}
                  value={DAForm.siteAccessibility}
                  options={[
                    STANDARD,
                    POOR_FIELD_CONDITIONS,
                    POOR_ROAD_CONDITIONS,
                    SWAMP,
                    ISLAND,
                  ]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm(
                      "siteAccessibility",
                      value as SiteAccessibility
                    )
                  }
                />
              </FormRow>
              <FormRow className="pl-1.5">
                <TextAreaControl
                  label="Accessibility Notes"
                  error={formErrors.includes("siteAccessibilityNotes")}
                  value={DAForm.siteAccessibilityNotes}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("siteAccessibilityNotes", value)
                  }
                />
              </FormRow>
              <FormRow>
                <div className="w-full flex flex-wrap justify-between items-center mb-1">
                  <p className="text-medium">Location of Broken Items</p>
                  <div className="flex flex-no-wrap items-center">
                    <p className="mr-0.75">Select Type:</p>
                    <div className="flex flex-no-wrap border border-dte-500 items-center">
                      <button
                        className={clsx(
                          "p-0.25",
                          brokenItemSelection === "Pole"
                            ? "bg-dte-500 text-white"
                            : "bg-white text-gray-400"
                        )}
                        onClick={() => setBrokenItemSelection("Pole")}
                      >
                        Pole
                      </button>
                      <button
                        className={clsx(
                          "p-0.25",
                          brokenItemSelection === "Cross Arm"
                            ? "bg-dte-500 text-white"
                            : "bg-white text-gray-500"
                        )}
                        onClick={() => setBrokenItemSelection("Cross Arm")}
                      >
                        Cross Arm
                      </button>
                    </div>
                  </div>
                </div>
                <DAMap
                  onMapClick={(coord) => {
                    if (brokenItemSelection === "Pole") {
                      setDAForm((prev) => ({
                        ...prev,
                        brokenPolesLocations: [
                          ...prev.brokenPolesLocations,
                          {
                            location: coord,
                            notes: "",
                            poleInfo: "",
                            quantity: 1,
                          },
                        ],
                      }));
                    } else {
                      setDAForm((prev) => ({
                        ...prev,
                        crossArmsLocations: [
                          ...prev.crossArmsLocations,
                          { location: coord, notes: "" },
                        ],
                      }));
                    }
                  }}
                  brokenCrossArms={DAForm.crossArmsLocations.map(
                    (x) => x.location
                  )}
                  brokenPoles={DAForm.brokenPolesLocations.map(
                    (x) => x.location
                  )}
                />
                <div className="flex flex-col w-full">
                  <p
                    className={clsx(
                      "text-medium my-1",
                      formErrors.includes("brokenPolesLocations") &&
                        "error-control"
                    )}
                  >
                    Poles
                  </p>
                  <div className="ml-1.5">
                    {DAForm.brokenPolesLocations.length > 0 ? (
                      DAForm.brokenPolesLocations.map((bp, idx) => (
                        <BrokenItem
                          key={idx}
                          type="Pole"
                          brokenItem={bp}
                          index={idx}
                          arePolesOrdered={DAForm.arePolesOrdered}
                          formErrors={formErrors}
                          removeItem={(index) =>
                            setDAForm((prev) => ({
                              ...prev,
                              brokenPolesLocations:
                                prev.brokenPolesLocations.filter(
                                  (x, i) => i !== index
                                ),
                            }))
                          }
                          changeType={switchBrokenItemType}
                          updateNote={(notes) =>
                            setDAForm((prev) => ({
                              ...prev,
                              brokenPolesLocations:
                                prev.brokenPolesLocations.map((x, i) => {
                                  if (i === idx) {
                                    return { ...x, notes: notes };
                                  } else {
                                    return x;
                                  }
                                }),
                            }))
                          }
                          updatePoleInfo={(info) =>
                            setDAForm((prev) => ({
                              ...prev,
                              brokenPolesLocations:
                                prev.brokenPolesLocations.map((x, i) => {
                                  if (i === idx) {
                                    return { ...x, poleInfo: info };
                                  } else {
                                    return x;
                                  }
                                }),
                            }))
                          }
                          updatePoleQuantity={(q) =>
                            setDAForm((prev) => ({
                              ...prev,
                              brokenPolesLocations:
                                prev.brokenPolesLocations.map((x, i) => {
                                  if (i === idx) {
                                    return { ...x, quantity: q };
                                  } else {
                                    return x;
                                  }
                                }),
                            }))
                          }
                        />
                      ))
                    ) : (
                      <p className="text-gray-400 italic">
                        Select the type above then click or tap on the map to
                        add a pin.
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <p
                    className={clsx(
                      "text-medium my-1",
                      formErrors.includes("crossArmsLocations") &&
                        "error-control"
                    )}
                  >
                    Cross Arms
                  </p>
                  <div className="ml-1.5">
                    {DAForm.crossArmsLocations.length > 0 ? (
                      DAForm.crossArmsLocations.map((ca, idx) => (
                        <BrokenItem
                          key={idx}
                          type="Cross Arm"
                          brokenItem={ca}
                          index={idx}
                          arePolesOrdered={DAForm.arePolesOrdered}
                          formErrors={formErrors}
                          removeItem={(index) =>
                            setDAForm((prev) => ({
                              ...prev,
                              crossArmsLocations:
                                prev.crossArmsLocations.filter(
                                  (x, i) => i !== index
                                ),
                            }))
                          }
                          changeType={switchBrokenItemType}
                          updateNote={(notes) =>
                            setDAForm((prev) => ({
                              ...prev,
                              crossArmsLocations: prev.crossArmsLocations.map(
                                (x, i) => {
                                  if (i === idx) {
                                    return { ...x, notes: notes };
                                  } else {
                                    return x;
                                  }
                                }
                              ),
                            }))
                          }
                        />
                      ))
                    ) : (
                      <p className="text-gray-400 italic">
                        Select the type above then click or tap on the map to
                        add a pin.
                      </p>
                    )}
                  </div>
                </div>
              </FormRow>
              {/* <FormRow>
                <PhotoUploadControl
                  label="Photos"
                  error={formErrors.includes("photos")}
                  values={DAForm.photos}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValues={(values) => updateDAForm("photos", values)}
                />
              </FormRow> */}
              <FormRow>
                <TextAreaControl
                  label="Notes"
                  error={formErrors.includes("notes")}
                  value={DAForm.notes}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) => updateDAForm("notes", value)}
                />
              </FormRow>
            </div>
          </div>
          <div className="w-full absolute bottom-0 shadow-main py-1 px-0.5 xxs:px-2.5">
            <div className="w-full inline-flex flex-no-wrap justify-between items-center">
              <button
                className="px-1 py-0.5 bg-red-600 text-white border border-solid border-red-600"
                onClick={() => {
                  close();
                }}
              >
                Close
              </button>
              <div>
                <button
                  className="px-1 py-0.5 text-dte-500 border border-solid border-dte-500 box-border mr-1"
                  onClick={saveForm}
                >
                  Save
                </button>
                <button
                  className="px-1 py-0.5 bg-dte-500 text-white border border-solid border-dte-500"
                  onClick={() => {
                    submitForm(close);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default DamageAssessmentFormModal;

const FormRow: React.FC<{ className?: string }> = ({ children, className }) => (
  <div className={clsx("flex flex-wrap w-full mb-2", className)}>
    {children}
  </div>
);

interface IBrokenItem {
  type: BrokenPoleSelection;
  brokenItem: BrokenPoleLocation | CrossArmsLocation;
  index: number;
  arePolesOrdered: boolean | null;
  removeItem: (index: number) => void;
  changeType: (index: number, originalType: BrokenPoleSelection) => void;
  updateNote: (note: string) => void;
  updatePoleInfo?: (info: string) => void;
  updatePoleQuantity?: (quantity: number) => void;
  formErrors: DAKey[];
}

const BrokenItem: React.FC<IBrokenItem> = (props) => {
  const getPoleTypeOption = (
    option: string | undefined
  ): DropdownOption | null => {
    return option
      ? getPoleTypeOptions().find((o) => o.value === option) || null
      : null;
  };

  const fieldHasError = (field: string) => {
    return props.formErrors?.find((e) => e === field) !== undefined;
  };

  return (
    <div className="flex flex-col mb-2">
      <div className="flex flex-no-wrap justify-between mb-0.25">
        <p
          className={clsx(
            "text-medium font-medium",
            ((props.type === "Pole" && fieldHasError("brokenPolesLocations")) ||
              (props.type === "Cross Arm" &&
                fieldHasError("crossArmsLocations"))) &&
              !props.brokenItem.notes &&
              "error-control"
          )}
        >
          {props.type} {props.index + 1} Notes{" "}
          <span className="text-red-600">*</span>
        </p>

        <div className="flex flex-no-wrap">
          <button
            onClick={() => props.changeType(props.index, props.type)}
            className="mr-1 text-dte-500 underline"
          >
            Switch Type
          </button>
          <button
            onClick={() => props.removeItem(props.index)}
            className="text-red-600 underline"
          >
            Remove Pin
          </button>
        </div>
      </div>
      <textarea
        value={props.brokenItem.notes}
        className="p-0.5 h-5.5 bg-white w-full border border-dte-500 "
        placeholder="Enter Notes..."
        onChange={(e) => props.updateNote(e.target.value)}
      />

      {props.updatePoleInfo && (
        <div className="flex flex-wrap w-full">
          <DropdownControl
            className="flex flex-grow w-!auto pr-2"
            label={props.type + " " + (props.index + 1) + " Type"}
            required={!props.arePolesOrdered}
            error={
              !props.arePolesOrdered &&
              !getPoleTypeOption(
                (props.brokenItem as BrokenPoleLocation).poleInfo
              ) &&
              fieldHasError("brokenPolesLocations")
            }
            value={getPoleTypeOption(
              (props.brokenItem as BrokenPoleLocation).poleInfo
            )}
            options={getPoleTypeOptions()}
            setValue={(value) => props.updatePoleInfo?.(value)}
          />
          {props.updatePoleQuantity && (
            <NumericControl
              controlClass="w-11.25"
              label={props.type + " " + (props.index + 1) + " Quantity"}
              error={fieldHasError("numberOfBrokenPoles")}
              required={!props.arePolesOrdered}
              value={(props.brokenItem as BrokenPoleLocation).quantity || 0}
              setValue={(value) => props.updatePoleQuantity?.(value)}
            />
          )}
        </div>
      )}
    </div>
  );
};
