import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../ReduxStore";
import * as api from "../lib/api/crewAssignmentNotificationApi";

export interface CrewAssignmentNotification {
  id: string;
  jobId: string;
  jobType: string;
  jobSubType: string;
  crewId: string;
  userId: string;
  name: string;
  phoneNumber?: string;
  crewAssignment: string;
  createdAt: number;
  read: boolean;
  ttl: number;
  _id: string;
}

export interface SignalRConfig {
  accessToken: string;
  url: string;
}
export interface CrewAssignmentNotificationState {
  notifications: CrewAssignmentNotification[];
  unreadNotificationCount: number;
  signalRConfig: SignalRConfig;
}

export const getCrewAssignmentNotifications = createAsyncThunk<
  CrewAssignmentNotification[],
  void,
  { state: RootState; dispatch: AppDispatch }
>("notifications/user", async (_, { dispatch, getState }) => {
  const { role } = getState();

  if (!role?.value) {
    return [];
  }

  const res = await api.getCrewAssignmentNotificationsForUser(
    role.value?.id.toUpperCase()
  );

  if (res.tag !== "ok") {
    return [];
  }

  if (!Array.isArray(res.data)) {
    return [];
  }

  return res.data;
});

export const setNotificationRead = createAsyncThunk<
  CrewAssignmentNotification,
  CrewAssignmentNotification,
  { state: RootState; reject: string } //reject value probably temporary
>(
  "notification/update",
  async (
    notification: CrewAssignmentNotification,
    { getState, rejectWithValue }
  ) => {
    const { role } = getState();

    if (!role?.value) {
      return rejectWithValue("No role was found");
    }

    const res = await api.setCrewAssignmentNotificationAsRead(notification.id);

    if (res.tag !== "ok") {
      return rejectWithValue(res.err);
    }

    return notification;
  }
);

export const getSignalRConfig = createAsyncThunk<
  SignalRConfig,
  void,
  { state: RootState; reject: string }
>("notifications/config", async (_, { getState, rejectWithValue }) => {
  const { role } = getState();

  if (!role?.value) {
    return rejectWithValue("No role was found");
  }

  const res = await api.negotiateSignalR();

  if (res.tag !== "ok") {
    return rejectWithValue(res.err);
  }

  return res.data;
});

const initialState: CrewAssignmentNotificationState = {
  notifications: [],
  unreadNotificationCount: 0,
  signalRConfig: {} as SignalRConfig,
};

const getUnreadNotificationCount = (
  notifications: CrewAssignmentNotification[]
): number => {
  return notifications.filter((n) => !n.read).length;
};

export const crewAssignmentNotificationSlice = createSlice({
  name: "crewAssignmentNotifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCrewAssignmentNotifications.fulfilled,
      (state, action) => {
        state.notifications = action.payload;
        state.unreadNotificationCount = getUnreadNotificationCount(
          state.notifications
        );
      }
    );
    builder.addCase(setNotificationRead.fulfilled, (state, action) => {
      const index = state.notifications.findIndex(
        (n) => n.id === action.payload.id
      );
      state.notifications[index] = {
        ...state.notifications[index],
        read: true,
      };
      state.unreadNotificationCount = getUnreadNotificationCount(
        state.notifications
      );
    });
    builder.addCase(setNotificationRead.rejected, (state, action) => {
      console.log(action.payload);
      alert("Could not mark notification as read");
    });
    builder.addCase(getSignalRConfig.fulfilled, (state, action) => {
      state.signalRConfig = action.payload;
    });
    builder.addCase(getSignalRConfig.rejected, (state, action) => {
      console.log(action.payload);
    });
  },
});

export default crewAssignmentNotificationSlice.reducer;
