import React from "react";
import Popup from "reactjs-popup";
import {
  CrewStatusByCrewList,
  CrewAssignmentStatusUpdateReq,
  JobDetailShort,
  getCrewStatusByCrewList,
  updateCrewAssignmentStatus,
} from "../../lib/Api";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const buildCrewAckReq = (
  jobId: string,
  name: string,
  crewId: string
): CrewAssignmentStatusUpdateReq => ({
  msgId: jobId,
  jobId: jobId,
  comments: `${crewId} - ${name} updated Crew Assignment Status to Acknowledged`,
  crewAssignmentStatus: "Acknowledged",
});

const ERROR = "Failed to get crew status. Please try again later.";

type CrewBulkUpdateReq = {
  jobId: string;
  crewId: string;
};

const CrewBulkAck = (props: { jobs: JobDetailShort[]; crews: string[] }) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [crewStatus, setCrewStatus] = React.useState<CrewStatusByCrewList[]>(
    []
  );
  const [crewsBulkUpdateList, setCrewsBulkUpdateList] = React.useState<
    CrewBulkUpdateReq[]
  >([]);
  const [loadingData, setLoadingData] = React.useState<boolean>(false);
  const [updatingData, setUpdatingData] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (modalOpen && props.crews) {
      setLoadingData(true);
      getCrewStatusByCrewList(props.crews)
        .then((res) => {
          if (res.tag === "ok") {
            setCrewStatus(res.data);
            setCrewsBulkUpdateList(
              props.jobs
                .map((job) =>
                  res.data.filter(
                    (c) =>
                      c.jobId === job.jobId &&
                      props.crews.includes(c.crewId) &&
                      (c.crewStatus === "Dispatched" ||
                        c.crewStatus === "Dispatch Successful")
                  )
                )
                .flat()
                .map((c: CrewBulkUpdateReq) => c)
            );
          } else {
            alert(ERROR);
          }
          setLoadingData(false);
        })
        .catch((err) => {
          console.log(err);
          alert(ERROR);
        });
    }
  }, [modalOpen, props]);

  const handleSubmit = async (close: () => void) => {
    setUpdatingData(true);
    dispatch(setLoading(true));
    const name = role?.name ?? "";
    let hasError = false;

    for (const u of crewsBulkUpdateList) {
      const req = buildCrewAckReq(u.jobId, name, u.crewId);
      const res = await updateCrewAssignmentStatus(u.crewId, req);

      if (res.tag !== "ok") {
        hasError = true;
      }
    }

    if (hasError) {
      alert(
        "Crew assignment update failed, check your internet connection. You can resend the request through the Drafts page"
      );
    }
    setUpdatingData(false);
    dispatch(setLoading(false));
    close();
  };

  const handleChecked = (checked: boolean, jobId: string, crewId: string) => {
    if (checked) {
      setCrewsBulkUpdateList([{ jobId, crewId }, ...crewsBulkUpdateList]);
    } else {
      setCrewsBulkUpdateList(
        crewsBulkUpdateList.filter(
          (x) => !(x.jobId === jobId && x.crewId === crewId)
        )
      );
    }
  };

  const determineChecked = (jobId: string, crewId: string) => {
    if (
      crewsBulkUpdateList.filter(
        (x) => x.crewId === crewId && x.jobId === jobId
      ).length > 0
    ) {
      return true;
    }
    return false;
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setCrewsBulkUpdateList(
        props.jobs
          .map((job) =>
            crewStatus.filter(
              (c) =>
                c.jobId === job.jobId &&
                props.crews.includes(c.crewId) &&
                (c.crewStatus === "Dispatched" ||
                  c.crewStatus === "Dispatch Successful")
            )
          )
          .flat()
          .map((c: CrewBulkUpdateReq) => c)
      );
    } else {
      setCrewsBulkUpdateList([]);
    }
  };

  const isAllSelected = () => {
    return (
      crewsBulkUpdateList.length ===
      props.jobs
        .map((job) =>
          crewStatus.filter(
            (c) =>
              c.jobId === job.jobId &&
              props.crews.includes(c.crewId) &&
              (c.crewStatus === "Dispatched" ||
                c.crewStatus === "Dispatch Successful")
          )
        )
        .flat().length
    );
  };

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        onOpen={() => {
          setModalOpen(true);
        }}
        disabled={!props.jobs.length}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className="responsive-modal"
        trigger={
          <button
            className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
            disabled={!props.jobs.length}
          >
            Bulk Acknowledge
          </button>
        }
      >
        {(closeModal: () => void) => (
          <div className="text-black py-1 flex justify-center flex-col px-1">
            <h4 className="mb-1 text-center text-lg font-bold">
              Bulk Acknowledge
            </h4>
            {loadingData ? (
              <div className="text-center text-2xl">
                <FontAwesomeIcon
                  className="mr-0.5"
                  icon={faSpinner}
                  spin={true}
                />
                Loading data...
              </div>
            ) : updatingData ? (
              <div className="text-center text-2xl">
                <FontAwesomeIcon
                  className="mr-0.5"
                  icon={faSpinner}
                  spin={true}
                />
                Updating crew data, please wait..
              </div>
            ) : (
              <table className="table-auto bg-white w-full text-black border">
                <thead className="bg-gray-200">
                  <tr>
                    <th
                      scope="col"
                      className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap text-center"
                    >
                      <input
                        type="checkbox"
                        id="selectAll"
                        onChange={(e) => handleSelectAll(e.target.checked)}
                        checked={isAllSelected()}
                      />
                    </th>
                    <th
                      scope="col"
                      className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
                    >
                      Job
                    </th>
                    <th
                      scope="col"
                      className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
                    >
                      Crew
                    </th>
                    <th
                      scope="col"
                      className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
                    >
                      Crew Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.jobs.map(
                    (job) =>
                      job &&
                      crewStatus
                        .filter(
                          (c) =>
                            c.jobId === job.jobId &&
                            props.crews.includes(c.crewId) &&
                            (c.crewStatus === "Dispatched" ||
                              c.crewStatus === "Dispatch Successful")
                        )
                        .map((crew, idx) => (
                          <tr
                            className="hover:bg-gray-200"
                            key={crew.jobId + crew.crewId + idx}
                          >
                            <td className="border text-center py-0.5">
                              <input
                                checked={determineChecked(
                                  crew.jobId,
                                  crew.crewId
                                )}
                                onChange={(e) =>
                                  handleChecked(
                                    e.target.checked,
                                    crew.jobId,
                                    crew.crewId
                                  )
                                }
                                type="checkbox"
                                value={crew.jobId + "-" + crew.crewId}
                              />
                            </td>
                            <td className="border text-center py-0.5 px-0.5">
                              {job.jobDisplayId}
                            </td>
                            <td className="border text-center py-0.5">
                              {crew.crewId}
                            </td>
                            <td className="border text-center py-0.5">
                              {crew.crewStatus}
                            </td>
                          </tr>
                        ))
                  )}
                  {props.jobs.map(
                    (job) =>
                      job &&
                      crewStatus
                        .filter(
                          (c) =>
                            c.jobId === job.jobId &&
                            props.crews.includes(c.crewId) &&
                            !(
                              c.crewStatus === "Dispatched" ||
                              c.crewStatus === "Dispatch Successful"
                            )
                        )
                        .map((crew, idx) => (
                          <tr
                            className="hover:bg-gray-200"
                            key={crew.jobId + crew.crewId + idx + "-2"}
                          >
                            <td className="border text-center py-0.5"></td>
                            <td className="border text-center py-0.5 px-0.5">
                              {job.jobDisplayId}
                            </td>
                            <td className="border text-center py-0.5">
                              {crew.crewId}
                            </td>
                            <td className="border text-center py-0.5">
                              {crew.crewStatus}
                            </td>
                          </tr>
                        ))
                  )}
                </tbody>
              </table>
            )}
            {!updatingData && (
              <div className="w-full inline-flex flex-no-wrap justify-center items-center my-1">
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                  disabled={loadingData || crewsBulkUpdateList.length === 0}
                  onClick={() => {
                    handleSubmit(closeModal);
                  }}
                >
                  Acknowledge
                </button>
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  No
                </button>
              </div>
            )}
          </div>
        )}
      </Popup>
    </>
  );
};

export default CrewBulkAck;
