import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { getMapLink } from "../../lib/Utility";
import { JobDetailCrewAssignment } from "../../lib/Api";
import { useAppSelector } from "../../lib/hooks";

const DrivingDirectionsModal = (props: {
  crews: JobDetailCrewAssignment[] | undefined;
}) => {
  const role = useAppSelector((state) => state.role.value);
  const adresses = [
    ...new Set(
      props.crews
        ?.filter(
          (c) => role?.crews.includes(c.crewId) || role?.tag === "employee"
        )
        .map((crew) => crew.destination)
    ),
  ];

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className="responsive-modal wide"
        trigger={
          <button className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white">
            Driving Directions
          </button>
        }
      >
        {(close: () => void) => (
          <div className="text-black relative flex flex-col max-h-modal">
            <div className="flex flex-wrap justify-center px-2 max-h-full overflow-auto">
              <h1 className="w-full text-2xl pb-1 text-center">
                Driving directions
              </h1>
              <div className="w-full border-l border-r border-t border-gray-300 mb-0.5">
                {adresses
                  .map((a) => {
                    const crewsMap = props.crews?.filter(
                      (c) =>
                        (role?.crews.includes(c.crewId) ||
                          role?.tag === "employee") &&
                        c.destination === a
                    );
                    const first = crewsMap?.find((e) => e !== undefined);
                    return {
                      crews: [
                        ...new Set(
                          crewsMap?.filter((c) => c.crewId).map((c) => c.crewId)
                        ),
                      ].join(", "),
                      latitude: first?.latitude,
                      longitude: first?.longitude,
                      address: a,
                    };
                  })
                  .map((destinations, idx) => (
                    <div
                      className="py-0.5 px-1 min-h-3.5 flex justify-between border-b border-gray-300 items-center"
                      key={idx}
                    >
                      <div className="mr-2">
                        <b>{destinations.crews}</b>
                      </div>
                      <div className="mr-2 hidden-768">
                        {destinations.address}
                      </div>
                      <div className="text-right">
                        <div className={`py-0.5 text-center text-black`}>
                          {destinations.latitude && destinations.longitude ? (
                            <a
                              className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white nowrap"
                              href={getMapLink(
                                destinations.latitude,
                                destinations.longitude
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Driving Directions
                              <FontAwesomeIcon
                                className="ml-1"
                                icon={faExternalLinkAlt}
                              />
                            </a>
                          ) : (
                            <a
                              className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white disabled nowrap"
                              aria-disabled
                              onClick={(event) => event.preventDefault()}
                            >
                              Driving Directions
                              <FontAwesomeIcon
                                className="ml-1"
                                icon={faExternalLinkAlt}
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="w-full inline-flex flex-no-wrap justify-center my-1">
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                  onClick={() => {
                    close();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default DrivingDirectionsModal;
