import * as React from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
} from "react-leaflet";
import L, { LatLngLiteral } from "leaflet";
import "leaflet/dist/leaflet.css";

const subscriptionKey: string = process.env.REACT_APP_MAP_KEY;

/*
    Tileset ID specifies which data layers to render in the tiles. Can be:
                            
    'microsoft.base.road',  
    'microsoft.base.darkgrey',
    'microsoft.imagery', 
    'microsoft.weather.infrared.main', 
    'microsoft.weather.radar.main', 
    'microsoft.base.hybrid.road',
    'microsoft.base.labels.road '
*/
const tilesetId: string = "microsoft.base.road";

//The language of labels. Supported languages: https://docs.microsoft.com/en-us/azure/azure-maps/supported-languages
const language: string = "en-US";
const view = "Auto";

export interface Coordinates {
  lat: number;
  long: number;
}

interface IDAMap {
  onMapClick: (coordinates: Coordinates) => void;
  brokenPoles: Coordinates[];
  brokenCrossArms: Coordinates[];
}

const DAMap: React.FC<IDAMap> = (props) => {
  const userIcon = L.divIcon({
    className: "rounded-full bg-dte-500 border border-white",
  });
  const [userPos, setUserPos] = React.useState<[number, number] | null>(null);
  const [map, setMap] = React.useState<L.Map | null>(null);

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos: LatLngLiteral = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserPos([pos.lat, pos.lng]);
        },
        (error) => console.log(error)
      );
    }
  }, [map]);

  React.useEffect(() => {
    if (userPos && map) {
      map.setView(userPos);
    }
  }, [map, userPos]);

  return (
    <MapContainer
      className="w-full h-20 border border-gray-500"
      center={userPos ?? [42.331429, -83.045753]}
      zoom={15}
      whenCreated={setMap}
    >
      <TileLayer
        url={`https://atlas.microsoft.com/map/tile?subscription-key=${subscriptionKey}&api-version=2.1&tilesetId=${tilesetId}&zoom={z}&x={x}&y={y}&tileSize=256&language=${language}&view=${view}`}
      />
      {userPos && <Marker icon={userIcon} position={userPos} />}
      <BrokenItemMarkers
        brokenCrossArms={props.brokenCrossArms}
        brokenPoles={props.brokenPoles}
        onMapClick={props.onMapClick}
      />
    </MapContainer>
  );
};

const BrokenItemMarkers: React.FC<IDAMap> = (props) => {
  useMapEvent("click", (e) => {
    props.onMapClick({ lat: e.latlng.lat, long: e.latlng.lng });
  });

  const createIcon = (type: "Pole" | "Cross Arm", index: number) => {
    if (type === "Pole") {
      return new L.DivIcon({
        className: "bg-dte-500 rounded-full border border-white",
        html: `<p class="map-pin-label">${index + 1}</p>`,
      });
    } else {
      return new L.DivIcon({
        className: "bg-dtePurple-500 rounded-full border border-white",
        html: `<p class="map-pin-label">${index + 1}</p>`,
      });
    }
  };

  return (
    <>
      {props.brokenPoles.map((bp, i) => (
        <Marker
          icon={createIcon("Pole", i)}
          position={[bp.lat, bp.long]}
          key={"Pole" + i}
        />
      ))}
      {props.brokenCrossArms.map((ca, i) => (
        <Marker
          icon={createIcon("Cross Arm", i)}
          position={[ca.lat, ca.long]}
          key={"CrossArm" + i}
        />
      ))}
    </>
  );
};

export default DAMap;
