import { configureStore } from "@reduxjs/toolkit";
import jobsReducer from "./features/jobSlice";
import onlineReducer from "./features/onlineSlice";
import roleReducer from "./features/roleSlice";
import outageCountReducer from "./features/outageCountsSlice";
import draftsReducer from "./features/draftsSlice";
import crewAssignmentNotificationsReducer from "./features/crewAssignmentNotificationsSlice";
import treeTrimReducer from "./features/treeTrimSlice";

import { localstorageMiddleware } from "./lib/ReduxMiddleware";

export const store = configureStore({
  reducer: {
    jobs: jobsReducer,
    online: onlineReducer,
    role: roleReducer,
    outageCounts: outageCountReducer,
    drafts: draftsReducer,
    crewAssignmentNotifications: crewAssignmentNotificationsReducer,
    treeTrim: treeTrimReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localstorageMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
