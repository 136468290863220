import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap,
  faList,
  faPlug,
  faBan,
  faCog,
  faBars,
  faBell,
  faSpinner,
  faUsers,
  faClipboardList,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../lib/hooks";

const isActive = (currentPath: string, path: string) => {
  if (currentPath === path) {
    return { color: "#ff9900" };
  } else {
    return { color: "#ffffff" };
  }
};

const getTitle = (path: string) => {
  switch (path) {
    case "/list":
      return "Job List";
    case "/crews":
      return "Crews";
    case "/map":
      return "Map";
    case "/outages":
      return "Customers Out";
    case "/drafts":
      return "Drafts";
    case "/settings":
      return "Settings";
    case "/notifications":
      return "Notifications";
    case "/workorders":
      return "Work Orders";
    default:
      if (path.startsWith("/details")) {
        const id = path.split("/")[2] ?? "Outage Status";
        return id;
      } else {
        return "Outage Status";
      }
  }
};

const Nav = () => {
  const location = useLocation();
  const role = useAppSelector((state) => state.role.value);
  const drafts = useAppSelector((state) => state.drafts);
  const unreadNotificationCount = useAppSelector(
    (state) => state.crewAssignmentNotifications.unreadNotificationCount
  );
  const loading = useAppSelector((state) => state.jobs.loading);

  const [menu, setMenu] = React.useState(false);

  return (
    <>
      <div className="hidden lg:inline-flex flex-no-wrap items-center justify-between w-full z-50 py-0.5 relative">
        <div className="inline-flex flex-no-wrap justify-around space-x-1 ml-2 items-center">
          {(role?.tag === "employee" || role?.supervisor) && (
            <Link
              style={isActive(location.pathname, "/map")}
              to="/map"
              className="text-2.5"
              onClick={() => setMenu(false)}
            >
              <FontAwesomeIcon
                icon={faMap}
                className="hover:text-gray-200 align-baseline"
              />
            </Link>
          )}

          <Link
            className="nav-link"
            style={isActive(location.pathname, "/list")}
            to="/list"
          >
            <FontAwesomeIcon
              icon={faList}
              className="hover:text-gray-200 align-baseline text-2.5"
            />
          </Link>

          {role?.foreman && (
            <Link
              className="nav-link"
              style={isActive(location.pathname, "/crews")}
              to="/crews"
            >
              <FontAwesomeIcon
                icon={faUsers}
                className="hover:text-gray-200 align-baseline text-2.5"
              />
            </Link>
          )}
          {role?.tag === "employee" && (
            <Link
              className="nav-link"
              style={isActive(location.pathname, "/outages")}
              to="/outages"
            >
              <div className="fa-layers fa-fw text-4xl hover:text-gray-200 align-baseline">
                <FontAwesomeIcon
                  icon={faPlug}
                  className="text-2xl transform rotate-45"
                />
                <FontAwesomeIcon icon={faBan} />
              </div>
            </Link>
          )}
          <Link
            className="nav-link relative"
            style={isActive(location.pathname, "/drafts")}
            to="/drafts"
          >
            <FontAwesomeIcon
              icon={faClipboardList}
              className="hover:text-gray-200 align-baseline text-2.5"
            />
            {drafts.drafts.length > 0 && (
              <span className="badge">{drafts.drafts.length}</span>
            )}
          </Link>
          <Link
            className="nav-link relative"
            style={isActive(location.pathname, "/workorders")}
            to="/workorders"
          >
            <FontAwesomeIcon
              icon={faBriefcase}
              className="hover:text-gray-200 align-baseline text-2.5"
            />
          </Link>
        </div>
        <h4
          style={{ marginLeft: "calc(50% - 5rem)" }}
          className="text-3xl absolute w-15"
        >
          {getTitle(location.pathname)}
        </h4>
        <div className="inline-flex flex-no-wrap">
          <LoadingStatus visible={loading} />
          <ConnectionStatus />
          {(role?.tag === "employee" || role?.supervisor) && (
            <Link
              className="mr-2"
              style={isActive(location.pathname, "/settings")}
              to="/settings"
            >
              <FontAwesomeIcon
                icon={faCog}
                className="text-2.5 hover:text-gray-200"
              />
            </Link>
          )}

          <Link
            className="nav-link relative mr-2"
            style={isActive(location.pathname, "/notifications")}
            to="/notifications"
          >
            <FontAwesomeIcon
              icon={faBell}
              className="text-2.5 hover:text-gray-200"
            />
            {unreadNotificationCount > 0 && (
              <span className="badge">{unreadNotificationCount}</span>
            )}
          </Link>
        </div>
      </div>
      <div className="inline-flex lg:hidden flex-no-wrap items-center justify-between w-full relative z-50">
        <h4 className="text-base text-left p-0.5">
          {getTitle(location.pathname)}
        </h4>
        <div className="mr-1 inline-flex flex-no-wrap justify-center items-center">
          <LoadingStatus visible={loading} />
          <ConnectionStatus />
          <FontAwesomeIcon
            icon={faBars}
            className="text-2xl hover:text-gray-200"
            onClick={() => setMenu(!menu)}
          />
          <Link
            className="relative pl-1"
            style={isActive(location.pathname, "/notifications")}
            to="/notifications"
          >
            <FontAwesomeIcon
              icon={faBell}
              className="text-2x1 hover:text-gray-200"
            />
            {unreadNotificationCount > 0 && (
              <span className="badge badge-no-text"></span>
            )}
          </Link>
        </div>

        {menu && (
          <div className="absolute right-0 top-0 w-full z-30 bg-dte-500 mt-2.5 flex flex-col px-1">
            {role?.tag === "employee" && (
              <Link
                style={isActive(location.pathname, "/map")}
                to="/map"
                className="nav-link py-0.25"
                onClick={() => setMenu(false)}
              >
                Map
              </Link>
            )}

            <Link
              className="nav-link py-0.25"
              style={isActive(location.pathname, "/list")}
              to="/list"
              onClick={() => setMenu(false)}
            >
              Jobs
            </Link>
            {role?.foreman && (
              <Link
                className="nav-link py-0.25"
                style={isActive(location.pathname, "/crews")}
                onClick={() => setMenu(false)}
                to="/crews"
              >
                Crews
              </Link>
            )}
            {role?.tag === "employee" && (
              <Link
                className="nav-link py-0.25"
                style={isActive(location.pathname, "/outages")}
                to="/outages"
                onClick={() => setMenu(false)}
              >
                Outages
              </Link>
            )}
            <Link
              className="nav-link py-0.25 inline-flex flex-no-wrap items-center"
              style={isActive(location.pathname, "/drafts")}
              to="/drafts"
              onClick={() => setMenu(false)}
            >
              Drafts
              {drafts.drafts.length > 0 && (
                <span className="responsive-badge">{drafts.drafts.length}</span>
              )}
            </Link>

            <Link
              className="nav-link py-0.25 inline-flex flex-no-wrap items-center"
              style={isActive(location.pathname, "/workorders")}
              to="/workorders"
              onClick={() => setMenu(false)}
            >
              Work Orders
            </Link>

            {role?.tag === "employee" && (
              <Link
                className="nav-link pb-1 py-0.25"
                style={isActive(location.pathname, "/settings")}
                onClick={() => setMenu(false)}
                to="/settings"
              >
                Settings
              </Link>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Nav;

const LoadingStatus = (props: { visible: boolean }) => {
  if (props.visible === true) {
    return (
      <div className="inline-flex flex-no-wrap ml-1 items-center justify-center">
        <FontAwesomeIcon className="mt-0.25" icon={faSpinner} spin />
        <p className="hidden md:block ml-0.25">Fetching latest data</p>
      </div>
    );
  } else {
    return null;
  }
};

const ConnectionStatus = () => {
  const online = useAppSelector((state) => state.online.value);
  const color = online ? "bg-green-500" : "bg-red-500";
  const status = online ? "Connected" : "Disconnected";
  return (
    <div className="inline-flex flex-no-wrap w-8 items-center justify-center">
      <div className={`h-1 w-1 rounded-full ${color} mt-0.25`} />
      <p className="ml-0.25">{status}</p>
    </div>
  );
};
