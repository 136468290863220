import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import { dateFormat } from "../lib/Utility";
import {
  CrewAssignmentNotification,
  getCrewAssignmentNotifications,
  setNotificationRead,
} from "../features/crewAssignmentNotificationsSlice";
import NotificationMessage from "../components/Notifications/NotificationMessage";
import { trackLocationData } from "../lib/api/trackLocationApi";
import { setLoading } from "../features/jobSlice";
import { markNotificationsAsReadForUser } from "../lib/Api";

const Notifications = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);

  const notifications = useAppSelector(
    (state) => state.crewAssignmentNotifications.notifications
  );

  const markAsRead = async (notification: CrewAssignmentNotification) => {
    if (notification.read) {
      return;
    }
    await trackLocationData(
      "markNotificationAsRead",
      `${notification.jobId}-${notification.crewId}`,
      role!
    );
    dispatch(setNotificationRead(notification));
  };

  const markAllAsRead = async () => {
    dispatch(setLoading(true));
    if (!role?.id) {
      return;
    }
    const res = await markNotificationsAsReadForUser(role.id);
    if (res.tag === "ok") {
      dispatch(getCrewAssignmentNotifications());
    } else {
      alert("Failed to mark all notifications as read");
    }
    await trackLocationData(
      "markNotificationAsRead",
      "ALL_NOTIFICATIONS",
      role!
    );
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(getCrewAssignmentNotifications());
  }, [dispatch]);

  return (
    <>
      <div className="w-3/4 m-auto text-black flex flex-col mt-4">
        <div className="text-right">
          <button
            className="text-dte-500 underline background-transparent outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            onClick={async () => {
              markAllAsRead();
            }}
          >
            Mark All As Read
          </button>
        </div>
      </div>
      <div className="w-3/4 m-auto text-black flex flex-col justify-center mt-1">
        {notifications.length > 0
          ? notifications.map((notification: CrewAssignmentNotification) => {
              return (
                <React.Fragment key={notification.id}>
                  <div
                    className="flex border cursor-pointer"
                    onClick={async () => {
                      markAsRead(notification);
                    }}
                  >
                    <div className="w-3/4 p-1 md:p-0.5 flex">
                      <div className="flex-none w-3">
                        {!notification.read ? (
                          <span className="text-red-600 ml-1 mr-1">
                            &#11044;
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <NotificationMessage notification={notification} />
                      </div>
                    </div>
                    <div className="w-1/4 p-1 md:p-0.5 text-right">
                      {new Date(notification.createdAt)
                        .toLocaleString(...dateFormat)
                        .toUpperCase()}
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          : "You don't have any notifications at the moment."}
      </div>
    </>
  );
};

export default Notifications;
