import React from "react";
import clsx from "clsx";

export const MultiCheckControl: React.FC<{
  label: string;
  values: string[];
  options: string[];
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  setValues: (value: string[]) => void;
}> = ({ label, values, setValues, required, disabled, options, error }) => {
  return (
    <div className={clsx("flex flex-col", disabled && "disabled")}>
      <div className="flex flex-no-wrap mb-0.5">
        <label
          className={clsx("font-medium mr-0.25", error && "error-control")}
        >
          {label}
        </label>
        {required && <p className="text-red-600">*</p>}
      </div>
      <div className="flex flex-wrap">
        {options.map((x) => (
          <div key={x} className="flex flex-no-wrap mr-1.25 items-center">
            <input
              disabled={disabled}
              type="checkbox"
              id={x}
              checked={values.includes(x)}
              onChange={(e) =>
                e.target.checked
                  ? setValues([...values, x])
                  : setValues(values.filter((y) => y !== x))
              }
            />
            <label className="ml-0.5" htmlFor={x}>
              {x}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
